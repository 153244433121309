import React, { useState } from "react"
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap"
import Select from "react-select"
import { Formik } from "formik"
import * as Yup from "yup"

const ViewModal = ({isOpen,toggle}) => {
  const [modal, setModal] = useState(false)


  const stages = [
    { value: "stage1", label: "Stage 1" },
    { value: "stage2", label: "Stage 2" },
    { value: "stage3", label: "Stage 3" },
  ]

  const inspectors = [
    { value: "inspectorA", label: "Inspector A" },
    { value: "inspectorB", label: "Inspector B" },
    { value: "inspectorC", label: "Inspector C" },
  ]

  const states = [
    { value: "Confirm", label: "Confirm" },
    { value: "Hold", label: "Hold" },
  ]

  const initialValues = {
    stage: null,
    date: "",
    inspector: null,
    state: null,
    comment: "",
  }

const validationSchema = Yup.object({
    stage: Yup.object().nullable().required("Stage is required"),
    date: Yup.date().required("Date is required"),
    inspector: Yup.object().nullable().required("Inspector is required"),
    state: Yup.object().nullable().required("State is required"),
    comment: Yup.string().when("state", {
        is: (state) => state && state.value === "Hold",
        then: Yup.string().required("Comment is required when state is Hold").max(500, "Maximum 500 characters allowed"),
        otherwise: Yup.string().max(500, "Maximum 500 characters allowed"),
    }),
})

  const handleSubmit = values => {
    console.log(values)
    toggle() // Close the modal on successful submit
  }

  return (
    <div>
      <Modal isOpen={isOpen} toggle={toggle}>
        <ModalHeader toggle={toggle}>Form in Modal</ModalHeader>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            setFieldValue,
            handleSubmit,
          }) => (
            <Form onSubmit={handleSubmit}>
              <ModalBody>
                <FormGroup>
                  <Label for="stage">Stage</Label>
                  <Select
                    id="stage"
                    options={stages}
                    value={values.stage}
                    onChange={option => setFieldValue("stage", option)}
                    onBlur={handleBlur("stage")}
                    isClearable
                  />
                  {touched.stage && errors.stage && (
                    <div className="text-danger">{errors.stage}</div>
                  )}
                </FormGroup>
                <FormGroup>
                  <Label for="date">Date</Label>
                  <Input
                    type="date"
                    id="date"
                    name="date"
                    value={values.date}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    invalid={touched.date && !!errors.date}
                  />
                  {touched.date && errors.date && (
                    <div className="text-danger">{errors.date}</div>
                  )}
                </FormGroup>
                <FormGroup>
                  <Label for="inspector">Inspector</Label>
                  <Select
                    id="inspector"
                    options={inspectors}
                    value={values.inspector}
                    onChange={option => setFieldValue("inspector", option)}
                    onBlur={handleBlur("inspector")}
                    isClearable
                  />
                  {touched.inspector && errors.inspector && (
                    <div className="text-danger">{errors.inspector}</div>
                  )}
                </FormGroup>
                <FormGroup>
                  <Label for="state">State</Label>
                  <Select
                    id="state"
                    options={states}
                    value={values.state}
                    onChange={option => setFieldValue("state", option)}
                    onBlur={handleBlur("state")}
                    isClearable
                  />
                  {touched.state && errors.state && (
                    <div className="text-danger">{errors.state}</div>
                  )}
                </FormGroup>
                {values.state && (
                  <FormGroup>
                    <Label for="comment">Comment to Client</Label>
                    <Input
                      type="textarea"
                      id="comment"
                      name="comment"
                      value={values.comment}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      invalid={touched.comment && !!errors.comment}
                    />
                    {touched.comment && errors.comment && (
                      <div className="text-danger">{errors.comment}</div>
                    )}
                  </FormGroup>
                )}
              </ModalBody>
              <ModalFooter>
                <Button color="secondary" onClick={toggle}>
                  Cancel
                </Button>
                <Button type="submit" color="primary">
                  Submit
                </Button>
              </ModalFooter>
            </Form>
          )}
        </Formik>
      </Modal>
    </div>
  )
}

export default ViewModal
