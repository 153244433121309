import React from "react"
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap"
import { Formik, Field } from "formik"
import * as Yup from "yup"

const ModifyModal = ({ isOpen, toggle }) => {
  const initialValues = {
    role: "",
  }

  const validationSchema = Yup.object({
    role: Yup.string().required("Please select a role"),
  })

  const handleSubmit = values => {
    console.log(values)
    // Handle form submission
  }

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>Change Role</ModalHeader>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ errors, touched, values, handleChange }) => (
          <Form onSubmit={e => e.preventDefault()}>
            <ModalBody>
              <FormGroup className="ms-5" tag="fieldset">
                <h5>Change Role : </h5>
                <div className="ms-5">
                  <FormGroup check>
                    <Label check>
                      <Input
                        name="role"
                        type="radio"
                        value="owner"
                        // checked={values.role === "owner"}
                        onChange={handleChange}
                      />{" "}
                      Owner
                    </Label>
                  </FormGroup>
                  <FormGroup check>
                    <Label check>
                      <Input
                        name="role"
                        type="radio"
                        value="coordinator"
                        // checked={values.role === "coordinator"}
                        onChange={handleChange}
                      />{" "}
                      Coordinator
                    </Label>
                  </FormGroup>
                  <FormGroup check>
                    <Label check>
                      <Input
                        name="role"
                        type="radio"
                        value="viewer"
                        onChange={handleChange}
                      />
                      Viewer
                    </Label>
                  </FormGroup>
                  {errors.role && touched.role && (
                    <div className="text-danger">{errors.role}</div>
                  )}
                </div>
              </FormGroup>
            </ModalBody>
            <ModalFooter>
              <Button type="button" color="secondary" onClick={toggle}>
                Cancel
              </Button>
              <Button
                type="submit"
                color="primary"
                onClick={() => handleSubmit(values)}
              >
                Update
              </Button>
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </Modal>
  )
}

export default ModifyModal
