import Select from "react-select"
import { Formik, useFormik } from "formik"
import React, { useState } from "react"
import { ModalHeader } from "react-bootstrap"
import {
  Button,
  Form,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
} from "reactstrap"
import ImagePicker from "common/ImageFullOptions"

function AddGuideModal({ isOpen, toggle }) {
  const [viewImageFullOptions, setViewImageFullOptions] = useState(false)
  const toggleImageFullOptions = () => {
    setViewImageFullOptions(!viewImageFullOptions)
  }
  const initialValues = {
    responsiblePerson: "",
    guideAR: "",
    guideEN: "",
  }
  const [errors,setErrors] = useState({guiderror:"You should upload both guides"})
  const validatehandler = values => {    
 if (
      (values.guideAR && !values.guideEN) ||
      (!values.guideAR && values.guideEN)
    ) {
      
      errors.guiderror = "You should upload both guides"
    } else {
      errors.guiderror = ""
    }
    setErrors(errors)
    return errors

  }
  const submithandler = values => {
    console.log(values)
  }
  console.log(errors)

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader>Add Guide</ModalHeader>
      <ModalBody>
        <Formik
          initialValues={{ ...initialValues }}
          onSubmit={submithandler}
          validate={validatehandler}
        >
          {props => (
            <Form
              onSubmit={e => {
                e.preventDefault()
                props.handleSubmit()
                return false
              }}
            >

              <div
                className="mb-3 form-control"
                disabled={props.values.responsiblePerson}
              >
                <div className="mb-3 d-flex justify-content-between align-items-center col-8">
                  <Label className="col-4">Guide AR : </Label>

                  <Input
                    type="file"
                    disabled={props.values.responsiblePerson}
                    onChange={e => {
                      props.setFieldValue("guideAR", e.target.value)
                    }}
                  />
                </div>
                <div className="mb-3 d-flex justify-content-between align-items-center col-8">
                  <Label className="col-4">Guide EN : </Label>

                  <Input
                    type="file"
                    disabled={props.values.responsiblePerson}
                    onChange={e => {
                      props.setFieldValue("guideEN", e.target.value)
                    }}
                  />
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </ModalBody>
      {errors?.guiderror && (
          <div className="text-danger me-2" dir="rtl">{errors.guiderror}</div>
        )}
      <ModalFooter>
 
        <Button color="secondary" onClick={toggle}>
          Cancel
        </Button>
        <Button color="primary" type="submit">
          Save
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default AddGuideModal
