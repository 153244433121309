import { useFormik } from "formik"
import React, { useEffect, useState } from "react"
import {
  Button,
  Col,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Table,
} from "reactstrap"
import PasswordChangeModal from "./PasswordChangeModal"
import UpdateUserModal from "./UpdateUserModal"
import ModifyModal from "./ModifyModal"
import ConfirmingAction from "common/ConfirmingAction"
import AddProject from "./AddProject"

const Manage = ({
  ManageToggle,
  selectedUser,
  editing,
  setEditing,
  setAuth,
}) => {
  const [isPasswordModalOpen, setPasswordModalOpen] = useState(false)
  const [isUpdateModalOpen, setUpdateModalOpen] = useState(false)
  const [isModifyModalOpen, setModifyModalOpen] = useState(false)
  const [isConfirmModalOpen, setConfirmModalOpen] = useState(false)
  const [isAddProjectModal,setAddProjectModal]=useState(false)


  let data = [
    {
      ref: "102155",
      role: "Admin",
    },
  ]

  const deleteFunc=()=>{

  }
  return (
    <Modal className="modal-lg" isOpen={selectedUser} toggle={ManageToggle}>
      <ModalHeader>
        Manage Account
        <button
          type="button"
          className="btn-close position-absolute end-0 top-0 m-3"
          onClick={ManageToggle}
        />
      </ModalHeader>
      <ModalBody>
        <div className="ms-5 mb-3">
          <Row>
            <Col>
              <Label>Account ID :</Label>{" "}
            </Col>
            <Col>
              <Label>Name :</Label>
            </Col>
          </Row>
          <Row>
            <Col>
              <Label>National ID :</Label>
            </Col>
            <Col>
              <Label>Mobile :</Label>{" "}
            </Col>
          </Row>
        </div>
        <div dir="rtl" className="mb-3">
          <Button onClick={() => setPasswordModalOpen(true)}>
            Change User Password{" "}
          </Button>
          <Button className="me-2">Send Recovery Link</Button>
        </div>
        <div
          dir="rtl"
          className="mb-5"
          onClick={() => setUpdateModalOpen(true)}
        >
          <Button>Update Data</Button>
        </div>

        <div className=" ms-3 d-flex justify-content-between align-items-center">
          <h5>Mange Projects :</h5>
          <Button onClick={()=>{setAddProjectModal(true)}} className="p-1 mb-1">Add</Button>
        </div>
        <Table className="ms-2 me-2 border  table-responsive project-list-table table-nowrap text-center align-middle">
          <thead>
            <th>#</th>
            <th>Ref</th>
            <th>Role</th>
            <th>Action</th>
          </thead>
          {data.map((item, key) => (
            <tbody key={key}>
              <td>{key + 1}</td>
              <td>{item.ref}</td>
              <td>{item.role}</td>
              <td>
                <span className="p-0 me-1">
                  <button onClick={()=>setModifyModalOpen(true)} className="btn btn-primary btn-sm ">Modify</button>
                </span>
                <span className="p-0">
                  <button onClick={()=>setConfirmModalOpen(true)} className="btn btn-primary btn-sm">Remove</button>
                </span>
              </td>
            </tbody>
          ))}
        </Table>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={ManageToggle}>
          Close
        </Button>
      </ModalFooter>
      {isPasswordModalOpen && (
        <PasswordChangeModal
          isOpen={isPasswordModalOpen}
          toggle={() => setPasswordModalOpen(!isPasswordModalOpen)}
        />
      )}
      {isUpdateModalOpen && (
        <UpdateUserModal
          isOpen={isUpdateModalOpen}
          toggle={() => setUpdateModalOpen(!isUpdateModalOpen)}
        />
      )}
      {isModifyModalOpen && (
        <ModifyModal
          isOpen={isModifyModalOpen}
          toggle={() => setModifyModalOpen(!isModifyModalOpen)}
        />
      )}
      {isConfirmModalOpen && (
        <ConfirmingAction
        ConfirmeModel={isConfirmModalOpen}
        Confirmetoggle={() => setConfirmModalOpen(!isConfirmModalOpen)}
          massege={"Are you sure you want to delete this Project?"}
          confirmFunc={deleteFunc}
        />
      )}
      {isAddProjectModal&&(
        <AddProject
        isOpen={isAddProjectModal}
        toggle={() => setAddProjectModal(!isAddProjectModal)}
        />
      )
      }

    </Modal>
  )
}

export default Manage
