import axios from "axios"

import React, { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import {

  Table,

} from "reactstrap"

const InfoTable = ({ ProjectID, setAuth }) => {
  const history = useHistory()

  const [projectInfo, setProjectInfo] = useState()

  useEffect(() => {
    const formData = {
      PerToken: localStorage.getItem("token"),
      PerUserID: localStorage.getItem("id"),
      PerRoleID: localStorage.getItem("userRoleID"),
      ProjectID: ProjectID,
    }
    axios
      .post("https://test.rdapp.net/api/PDinfo.php", formData)
      .then(res => {
        if (res.data.error === true && res.data.message === "Access denied!") {
          setAuth(true)
          setTimeout(() => {
            history.push("/logout")
            setTimeout(() => {
              history.push("/login")
            }, 1000)
          }, 4000)
        }

        if (res.data.error === false) {
          // console.log("res=================", res.data)
          let array = Object.values(res.data)
          array.splice(-1)
          // console.log("array =================", array)
          setProjectInfo(array[0])
        }
      })
      .catch(err => console.log("error------>", err))
  }, [])

  return (
    <div className="table-responsive">
      {projectInfo && (
        <Table bordered>
          <tbody>
            <tr>
              <th>Owner Name</th>
              <td>{projectInfo.OwnerName}</td>
              <th>Owner Email</th>
              <td>{projectInfo.OwnerEmail}</td>
              <th>Owner Mobile</th>
              <td>{projectInfo.OwnerMobile}</td>
            </tr>
            <tr>
              <th>Manager Name</th>
              <td>{projectInfo.ManagerName}</td>
              <th>Manager Email</th>
              <td>{projectInfo.ManagerEmail}</td>
              <th>Manager Mobile</th>
              <td>{projectInfo.ManagerMobile}</td>
            </tr>
            <tr>
              <th>Contractor Name</th>
              <td>{projectInfo.ConstructorName}</td>
              <th>Contractor Email</th>
              <td>{projectInfo.ConstructorEmail}</td>
              <th>Contractor Mobile</th>
              <td>{projectInfo.ConstructorMobile}</td>
            </tr>

            <tr>
              <th>Sub Contractor Name</th>
              <td>{projectInfo.HiddenConstructorName}</td>
            </tr>
            <tr>
              <th>Supervisor Office Name</th>
              <td>{projectInfo.SupervisorOfficeName}</td>
            </tr>
            <tr>
              <th>Designer Office Name</th>
              <td>{projectInfo.DesignerOfficeName}</td>
            </tr>
            <tr>
              <th>City Name</th>
              <td>{projectInfo.CityNameEN}</td>
            </tr>
          </tbody>
        </Table>
      )}
    </div>
  )
}

export default InfoTable
