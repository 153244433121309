import React from 'react'
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';


function Communication({ toggle, isOpen, data }) {
  return (
   
      
      <Modal isOpen={isOpen} toggle={toggle}>
        <ModalHeader toggle={toggle}>Communication Details</ModalHeader>
        <ModalBody>
          <p><strong>Name :</strong> {data.name}</p>
          <p><strong>Mobile :</strong> {data.mobile}</p>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggle}>Close</Button>
        </ModalFooter>
      </Modal>

  );
}

export default Communication;

