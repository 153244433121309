import PropTypes from "prop-types"
import React, { useEffect, useRef } from "react"

// //Import Scrollbar
import SimpleBar from "simplebar-react"

// MetisMenu
import MetisMenu from "metismenujs"
import { withRouter } from "react-router-dom"
import { Link } from "react-router-dom"

//i18n
import { withTranslation } from "react-i18next"
import { convertPermission } from "permissionUtils"
import { SvgIcon } from "@material-ui/core"

const useName = localStorage.getItem("useName")

const SidebarContent = props => {
  const userID = localStorage.getItem("id")
  const ref = useRef()
  // Use ComponentDidMount and ComponentDidUpdate method symultaniously
  useEffect(() => {
    const pathName = props.location.pathname

    const initMenu = () => {
      new MetisMenu("#side-menu")
      let matchingMenuItem = null
      const ul = document.getElementById("side-menu")
      const items = ul.getElementsByTagName("a")
      for (let i = 0; i < items.length; ++i) {
        if (pathName === items[i].pathname) {
          matchingMenuItem = items[i]
          break
        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem)
      }
    }
    initMenu()
  }, [props.location.pathname])

  useEffect(() => {
    ref.current.recalculate()
  })

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300
      }
    }
  }

  function activateParentDropdown(item) {
    item.classList.add("active")
    const parent = item.parentElement
    const parent2El = parent.childNodes[1]
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show")
    }

    if (parent) {
      parent.classList.add("mm-active")
      const parent2 = parent.parentElement

      if (parent2) {
        parent2.classList.add("mm-show") // ul tag

        const parent3 = parent2.parentElement // li tag

        if (parent3) {
          parent3.classList.add("mm-active") // li
          parent3.childNodes[0].classList.add("mm-active") //a
          const parent4 = parent3.parentElement // ul
          if (parent4) {
            parent4.classList.add("mm-show") // ul
            const parent5 = parent4.parentElement
            if (parent5) {
              parent5.classList.add("mm-show") // li
              parent5.childNodes[0].classList.add("mm-active") // a tag
            }
          }
        }
      }
      scrollElement(item)
      return false
    }
    scrollElement(item)
    return false
  }

  // **************** Permissions ******************************
  const userPermissions = convertPermission(
    JSON.parse(localStorage.getItem("roles"))
  )
  // console.log("userPermissions", userPermissions)

  return (
    <React.Fragment>
      <SimpleBar className="h-100" ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            <li>
              <Link to="/##">
                <span> {props.t(useName)}</span>
              </Link>
            </li>

            <li className="menu-title">{props.t("Menu")} </li>

            <li>
              <Link to="/#" className="has-arrow ">
                <i className="bx bx-briefcase-alt-2"></i>
                <span>{props.t("Projects")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/projects-list">{props.t("Projects List")}</Link>
                </li>
                {(userPermissions.R12?.P === "1" ||
                  userPermissions.R12?.P === "2" ||
                  userPermissions.R12?.P === "3" ||
                  userPermissions.R12?.P === "4" ||
                  userPermissions.R12.G === "1") && (
                  <li>
                    <Link to="/project-costs">{props.t("Project Costs")}</Link>
                  </li>
                )}
              </ul>
            </li>

            {(userPermissions.R9.P === "1" ||
              userPermissions.R9.P === "2" ||
              userPermissions.R9.P === "3" ||
              userPermissions.R9.P === "4" ||
              userPermissions.R9.G === "1") && (
              <li>
                <Link to="/#" className="has-arrow ">
                  <i className="bx bxs-user-detail"></i>
                  <span>{props.t("Users")}</span>
                </Link>
                <ul className="sub-menu">
                  <li>
                    <Link to="/Users-list">{props.t("Users List")}</Link>
                  </li>
                </ul>
              </li>
            )}

            {/* ******************* Stages List *********************** */}
            {(userPermissions.R1.P === "1" ||
              userPermissions.R1.P === "2" ||
              userPermissions.R1.P === "3" ||
              userPermissions.R1.P === "4" ||
              userPermissions.R1.G === "1" ||
              userPermissions.R2.P === "1" ||
              userPermissions.R2.P === "2" ||
              userPermissions.R2.P === "3" ||
              userPermissions.R2.P === "4" ||
              userPermissions.R2.G === "1") && (
              <li>
                <Link to="/#" className="has-arrow ">
                  <i className="bx bxs-layer"></i>
                  <span>{props.t("Stages")}</span>
                </Link>
                <ul className="sub-menu">
                  <li>
                    <Link to="/stages">{props.t("Stages List")}</Link>
                  </li>
                </ul>
              </li>
            )}

            {/* ******************* Missing Data *********************** */}
            {(userPermissions.R3.P === "1" ||
              userPermissions.R3.P === "2" ||
              userPermissions.R3.P === "3" ||
              userPermissions.R3.P === "4" ||
              userPermissions.R3.G === "1") && (
              <li className="">
                <Link to="/missing-data">
                  <i className="bx bxs-error"></i>
                  <span>{props.t("Missing Data")} </span>
                </Link>
              </li>
            )}

            {/* ******************* Reports *********************** */}
            {(userPermissions.R1.P === "1" ||
              userPermissions.R1.P === "2" ||
              userPermissions.R1.P === "3" ||
              userPermissions.R1.P === "4" ||
              userPermissions.R1.G === "1" ||
              userPermissions.R2.P === "1" ||
              userPermissions.R2.P === "2" ||
              userPermissions.R2.P === "3" ||
              userPermissions.R2.P === "4" ||
              userPermissions.R2.G === "1" ||
              userPermissions.R3.P === "1" ||
              userPermissions.R3.P === "2" ||
              userPermissions.R3.P === "3" ||
              userPermissions.R3.P === "4" ||
              userPermissions.R3.G === "1" ||
              userPermissions.R4.P === "1" ||
              userPermissions.R4.P === "2" ||
              userPermissions.R4.P === "3" ||
              userPermissions.R4.P === "4" ||
              userPermissions.R4.G === "1" ||
              userPermissions.R19.P === "1" ||
              userPermissions.R19.P === "2" ||
              userPermissions.R19.P === "3" ||
              userPermissions.R19.P === "4" ||
              userPermissions.R19.G === "1" ||
              userPermissions.R20.P === "1" ||
              userPermissions.R20.P === "2" ||
              userPermissions.R20.P === "3" ||
              userPermissions.R20.P === "4" ||
              userPermissions.R20.G === "1" ||
              userPermissions.R22.P === "1" ||
              userPermissions.R22.P === "2" ||
              userPermissions.R22.P === "3" ||
              userPermissions.R22.P === "4" ||
              userPermissions.R22.G === "1") && (
              <li>
                <Link to="/#" className="has-arrow ">
                  <i className="bx bxs-report"></i>
                  <span>{props.t("Reports")}</span>
                </Link>
                <ul className="sub-menu">
                  {(userPermissions.R3.P === "1" ||
                    userPermissions.R3.P === "2" ||
                    userPermissions.R3.P === "3" ||
                    userPermissions.R3.P === "4" ||
                    userPermissions.R3.G === "1") && (
                    <li>
                      <Link to="/Reports">{props.t("RD-0 Reports")}</Link>
                    </li>
                  )}
                  <li>
                    <Link to="/rd1-reports">{props.t("RD-1 Reports")}</Link>
                  </li>
                  <li>
                    <Link to="/rd2-reports">{props.t("RD-2 Reports")}</Link>
                  </li>

                  {(userPermissions.R1.P === "1" ||
                    userPermissions.R1.P === "2" ||
                    userPermissions.R1.P === "3" ||
                    userPermissions.R1.P === "4" ||
                    userPermissions.R1.G === "1" ||
                    userPermissions.R2.P === "1" ||
                    userPermissions.R2.P === "2" ||
                    userPermissions.R2.P === "3" ||
                    userPermissions.R2.P === "4" ||
                    userPermissions.R2.G === "1" ||
                    userPermissions.R3.G === "1" ||
                    userPermissions.R4.P === "1" ||
                    userPermissions.R4.P === "2" ||
                    userPermissions.R4.P === "3" ||
                    userPermissions.R4.P === "4" ||
                    userPermissions.R4.G === "1" ||
                    userPermissions.R24.P === "1" ||
                    userPermissions.R24.P === "2" ||
                    userPermissions.R24.P === "3" ||
                    userPermissions.R24.P === "4" ||
                    userPermissions.R24.G === "1") && (
                    <li>
                      <Link to="/rd3-reports">{props.t("RD-3 Reports")}</Link>
                    </li>
                  )}
                  <li>
                    <Link to="/rd-5">{props.t("RD-5 Reports")}</Link>
                  </li>
                  <li>
                    <Link to="/rd-DRreports">{props.t("DR Reports")}</Link>
                  </li>

                  <li>
                    <Link to="/inspection-reports">
                      {props.t("Inspection Reports")}
                    </Link>
                  </li>
                  {(userPermissions.R1.P === "1" ||
                    userPermissions.R1.P === "2" ||
                    userPermissions.R1.P === "3" ||
                    userPermissions.R1.P === "4" ||
                    userPermissions.R1.G === "1" ||
                    userPermissions.R2.P === "1" ||
                    userPermissions.R2.P === "2" ||
                    userPermissions.R2.P === "3" ||
                    userPermissions.R2.P === "4" ||
                    userPermissions.R2.G === "1" ||
                    userPermissions.R3.P === "1" ||
                    userPermissions.R3.P === "2" ||
                    userPermissions.R3.P === "3" ||
                    userPermissions.R3.P === "4" ||
                    userPermissions.R3.G === "1" ||
                    userPermissions.R4.P === "1" ||
                    userPermissions.R4.P === "2" ||
                    userPermissions.R4.P === "3" ||
                    userPermissions.R4.P === "4" ||
                    userPermissions.R4.G === "1" ||
                    userPermissions.R20.P === "1" ||
                    userPermissions.R20.P === "2" ||
                    userPermissions.R20.P === "3" ||
                    userPermissions.R20.P === "4" ||
                    userPermissions.R20.G === "1" ||
                    userPermissions.R22.P === "1" ||
                    userPermissions.R22.P === "2" ||
                    userPermissions.R22.P === "3" ||
                    userPermissions.R22.P === "4" ||
                    userPermissions.R22.G === "1") && (
                    <li>
                      <Link to="/rd6-reports">{props.t("RD-6 Reports")}</Link>
                    </li>
                  )}

                  {(userPermissions.R1.P === "1" ||
                    userPermissions.R1.P === "2" ||
                    userPermissions.R1.P === "3" ||
                    userPermissions.R1.P === "4" ||
                    userPermissions.R1.G === "1" ||
                    userPermissions.R2.P === "1" ||
                    userPermissions.R2.P === "2" ||
                    userPermissions.R2.P === "3" ||
                    userPermissions.R2.P === "4" ||
                    userPermissions.R2.G === "1" ||
                    userPermissions.R3.P === "1" ||
                    userPermissions.R3.P === "2" ||
                    userPermissions.R3.P === "3" ||
                    userPermissions.R3.P === "4" ||
                    userPermissions.R3.G === "1" ||
                    userPermissions.R4.P === "1" ||
                    userPermissions.R4.P === "2" ||
                    userPermissions.R4.P === "3" ||
                    userPermissions.R4.P === "4" ||
                    userPermissions.R4.G === "1" ||
                    userPermissions.R21.P === "1" ||
                    userPermissions.R21.P === "2" ||
                    userPermissions.R21.P === "3" ||
                    userPermissions.R21.P === "4" ||
                    userPermissions.R21.G === "1" ||
                    userPermissions.R22.P === "1" ||
                    userPermissions.R22.P === "2" ||
                    userPermissions.R22.P === "3" ||
                    userPermissions.R22.P === "4" ||
                    userPermissions.R22.G === "1") && (
                    <li>
                      <Link to="/rd7-reports">{props.t("RD-7 Reports")}</Link>
                    </li>
                  )}
                </ul>
              </li>
            )}

            {/* ******************* Tickets *********************** */}
            {(userPermissions.R11.P === "1" ||
              userPermissions.R11.P === "2" ||
              userPermissions.R11.P === "3" ||
              userPermissions.R11.P === "4") && (
              <li className="">
                <Link to="/tickets">
                  <i className="mdi mdi-ticket-confirmation-outline"></i>
                  <span>{props.t("Tickets")} </span>
                </Link>
              </li>
            )}

            {/* ******************* Visits *********************** */}
                      {/* {
                      <li>
                      <Link to="/visits">
                        <i className="bx bx-calendar"></i>
                        <span>{props.t("Visits")}</span>
                      </Link>
                      </li>
                      } */}

                      {/* ******************* RD6 Addendum *********************** */}
            {/* {(userPermissions.R2.P === "1" ||
              userPermissions.R2.P === "2" ||
              userPermissions.R2.P === "3" ||
              userPermissions.R2.P === "4" ||
              userPermissions.R4.P === "1" ||
              userPermissions.R4.P === "2" ||
              userPermissions.R4.P === "3" ||
              userPermissions.R4.P === "4" ||
              userPermissions.R20.P === "1" ||
              userPermissions.R20.P === "2" ||
              userPermissions.R20.P === "3" ||
              userPermissions.R20.P === "4") && (
              <li className="">
                <Link to="/rd6addendum">
                  <i className="mdi mdi-ticket-confirmation-outline"></i>
                  <span>{props.t("RD6 Addendum")} </span>
                </Link>
              </li>
            )} */}

            {/* ******************** Quotations ************************ */}
            {(userPermissions.R13.P === "1" ||
              userPermissions.R13.P === "2" ||
              userPermissions.R13.P === "3" ||
              userPermissions.R13.P === "4" ||
              userPermissions.R13.G === "1" ||
              userPermissions.R14.P === "1" ||
              userPermissions.R14.P === "2" ||
              userPermissions.R14.P === "3" ||
              userPermissions.R14.P === "4" ||
              userPermissions.R14.G === "1" ||
              // userPermissions.R2.P === "1" ||
              // userPermissions.R2.P === "2" ||
              // userPermissions.R2.P === "3" ||
              // userPermissions.R2.P === "4" ||
              // userPermissions.R2.G === "1" ||
              // userPermissions.R4.P === "1" ||
              // userPermissions.R4.P === "2" ||
              // userPermissions.R4.P === "3" ||
              // userPermissions.R4.P === "4" ||
              // userPermissions.R4.G === "1" ||
              userPermissions.R8.P === "1" ||
              userPermissions.R8.P === "2" ||
              userPermissions.R8.P === "3" ||
              userPermissions.R8.P === "4" ||
              userPermissions.R8.G === "1") && (
              <li>
                <Link to="/#" className="has-arrow ">
                  <i className="bx bxs-detail"></i>
                  <span>{props.t("Quotations")}</span>
                </Link>
                <ul className="sub-menu">
                  {(userPermissions.R13.P === "1" ||
                    userPermissions.R13.P === "2" ||
                    userPermissions.R13.P === "3" ||
                    userPermissions.R13.P === "4" ||
                    userPermissions.R13.G === "1") && (
                    <li>
                      <Link to="/project-quotations">
                        {props.t("Project Quotations")}
                      </Link>
                    </li>
                  )}

                  {(userPermissions.R14.P === "1" ||
                    userPermissions.R14.P === "2" ||
                    userPermissions.R14.P === "3" ||
                    userPermissions.R14.P === "4" ||
                    userPermissions.R14.G === "1") && (
                    <li>
                      <Link to="/rd-7">{props.t("RD7 Quotations")}</Link>
                    </li>
                  )}

                  {(userPermissions.R8.P === "1" ||
                    userPermissions.R8.P === "2" ||
                    userPermissions.R8.P === "3" ||
                    userPermissions.R8.P === "4" ||
                    userPermissions.R8.G === "1") && (
                    <li>
                      <Link to="/additional-visit">
                        {props.t("Additional Visit")}
                      </Link>
                    </li>
                  )}
                </ul>
              </li>
            )}
            {/* ************Accountion*****************/}
            {(userPermissions.R29.P === "1" ||
              userPermissions.R29.P === "2" ||
              userPermissions.R29.P === "3" ||
              userPermissions.R29.P === "4" ||
              userPermissions.R29.G == "1") && (
              <li>
                <Link to="/#" className="has-arrow ">
                  <i className="bx bx-calculator"></i>
                  <span>{props.t("Accounting")}</span>
                </Link>
                <ul className="sub-menu">
                  <li>
                    <Link to="/sadad">{props.t("Sadad")}</Link>
                  </li>
                </ul>
              </li>
            )}
            {/* ******************* Big Data *********************** */}
            {/* {(userPermissions.R3.P === "1" ||
              userPermissions.R3.P === "2" ||
              userPermissions.R3.P === "3" ||
              userPermissions.R3.P === "4" ||
              userPermissions.R3.G === "1") && (
              <li className="">
                <Link to="/bigData">
                  <i className="bx bxs-data"></i>
                  <span>{props.t("Big Data")} </span>
                </Link>
              </li>
            )} */}

            {/* ******************* End User *********************** */}
            {/* {
              <li className="">
                <Link to="/end-user">
                  <i className="bx bxs-user-detail"></i>
                  <span>{props.t("End User")} </span>
                </Link>
              </li>
            } */}

            {/* <li className=" bottom-0 end-0 start-0 ">
              <Link to="/#" className="has-arrow ">
                <i className="bx bxs-cog"></i>
                <span>{props.t("Doc Guide")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/guide">
                    <span>{props.t("Contractor Guide")} </span>
                  </Link>
                </li>
              </ul>
            </li> */}

            <li className=" bottom-0 end-0 start-0 ">
              <Link to="/#" className="has-arrow ">
                <i className="bx bxs-cog"></i>
                <span>{props.t("Settings")}</span>
              </Link>
              <ul className="sub-menu">
                {(userPermissions.R8.P === "1" ||
                  userPermissions.R8.P === "2" ||
                  userPermissions.R8.P === "3" ||
                  userPermissions.R8.P === "4" ||
                  userPermissions.R8.G === "1") && (
                  <li>
                    <Link to="/roles">
                      <span>{props.t("User Permissions")} </span>
                    </Link>
                  </li>
                )}
                {(userPermissions.R9.P === "1" ||
                  userPermissions.R9.P === "2" ||
                  userPermissions.R9.P === "3" ||
                  userPermissions.R9.P === "4" ||
                  userPermissions.R9.G === "1" ||
                  userPermissions.R1.G === "1" ||
                  userPermissions.R2.G === "1" ||
                  userPermissions.R3.G === "1" ||
                  userPermissions.R4.G === "1") && (
                  <li>
                    <Link to="/company">
                      <span>{props.t("Company Structure")} </span>
                    </Link>
                  </li>
                )}

                {/* {(userPermissions.R9.P === "1" ||
                  userPermissions.R9.P === "2" ||
                  userPermissions.R9.P === "3" ||
                  userPermissions.R9.P === "4" ||
                  userPermissions.R9.G === "1" ||
                  userPermissions.R1.G === "1" ||
                  userPermissions.R2.G === "1" ||
                  userPermissions.R3.G === "1" ||
                  userPermissions.R4.G === "1") && (
                  <li>
                    <Link to="/specialist">
                      <span>{props.t("Specialist Structure")} </span>
                    </Link>
                  </li>
                )} */}
                {userPermissions.R12.G === "1" && (
                  <li>
                    <Link to="/cost-estimation">
                      <span>{props.t("Project Cost Estimation")} </span>
                    </Link>
                  </li>
                )}

                {/* <li>
                  <Link to="/guide-setting">
                    <span>{props.t("Guide Settings")} </span>
                  </Link>
                </li> */}
                {/* <li>
                  <Link to="/edit-user-app">
                    <span>{props.t("Edit End-User APP")} </span>
                  </Link>
                </li> */}
                <li>
                  <Link to="/system-settings">
                    <span>{props.t("System Settings")} </span>
                  </Link>
                </li>
              </ul>
            </li>

            <li className="">
              <Link to="/aboutUs">
                <i className="mdi mdi-account-supervisor-circle"></i>
                <span>{props.t("About Us")} </span>
              </Link>
            </li>
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  )
}

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(SidebarContent))
