import React, { useEffect, useState } from "react"
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Table,
} from "reactstrap"
import { Tab, Tabs } from "react-bootstrap"
import axios from "axios"
import { Formik } from "formik"
import * as yup from "yup"
import { useHistory } from "react-router-dom"
import { convertPermission } from "permissionUtils"

const ReportStatus = ({
  selectedItem,
  reportStatusModal,
reportStatusToggle,
  editing,
  setEditing,
  setAuth,
  rejectModal,
}) => {
  //   console.log("selectedItem", selectedItem)

  const history = useHistory()

  //************* form control ******/
  const [modal, setmodal] = useState(true)
  const toggle = () => {
    reportStatusToggle()
  }
  const onClose = () => {
    reportStatusToggle()
  }

  // ********************** Permissions ******************************
  const userPermissions = convertPermission(
    JSON.parse(localStorage.getItem("roles"))
  )

  // *********************** Submit handler ******************************** //
  const submitHandler = values => {
    let formData = {
      PerToken: localStorage.getItem("token"),
      PerUserID: localStorage.getItem("id"),
      PerRoleID: localStorage.getItem("userRoleID"),
      PStageID: selectedItem.PStageID,
      Status: values.status,
      
    }
    values.status=="Rejected"&& 
       ( formData={...formData,RejectReason:values.rejectedReason})
    

    axios
      .post("https://test.rdapp.net/api/UpdateReportStatus", formData)
      .then(res => {
        if (res.data.error === true && res.data.message === "Access denied!") {
          setAuth(true)
          setTimeout(() => {
            history.push("/logout")
            setTimeout(() => {
              history.push("/login")
            }, 1000)
          }, 4000)
        }

        setEditing(!editing)
      })
      .catch(err => console.log(err))

    onClose()
  }
  //   console.log("selectedItem", selectedItem)
  let errors = { ReportStatus: "" }

  return (
    <Modal isOpen={modal} toggle={toggle} className="modal-md">
      <ModalHeader>
      Change Report Status
        <button
          type="button"
          className="btn-close position-absolute end-0 top-0 m-3"
          onClick={onClose}
        />
      </ModalHeader>
      <div className="modal-content">
        <ModalBody>
          <Formik
            enableReinitialize={true}
            initialValues={{
              ReportStatus: "",
              // manEmail: "",
              // manEmailCheck: false,
            }}
            validate={values => {
              if(!values.status){
                errors.ReportStatus = "Required"
              }  
              else if (values.status=="Rejected" && !values.rejectedReason) {
                errors.ReportStatus = "Required"
              } else {
                errors = {}
              }
              return errors
            }}
            onSubmit={submitHandler}
          >
            {props => (
              <Form onSubmit={props.handleSubmit}>
                <Row className="mt-5">
                  <Col
                    sm={4}
                    className="d-flex align-items-center justify-content-end"
                  >
                    <Label htmlFor="report-status">
                      Change status of report {selectedItem.StageName} :
                    </Label>
                  </Col>
                  <Col sm={4}>
                    <Input
                      type="select"
                      name="status"
                      id="report-status"
                      onChange={props.handleChange}
                      className="form-control border-dark"
                    >
                      <option value={""}>--Select--</option>
                      <option value={"Approved"}>{"Approved"}</option>
                      <option value={"Rejected"}>{"Rejected"}</option>
                    </Input>
                  </Col>
                  <Col className=" mt-3" sm={2}>
                      {errors.ReportStatus && (
                        <div className=" d-flex text-danger">Required</div>
                      )}
                    </Col>
                </Row>

                {props.values.status == "Rejected" && (
                  <Row className="mt-3">
                    <Col
                      sm={4}
                      className="d-flex align-items-center justify-content-end "
                    >
                      <Label>Reason : </Label>
                    </Col>
                    <Col sm={6}>
                      <Input
                        type="textarea"
                        name="rejectedReason"
                        onChange={props.handleChange}
                      />
                    </Col>
                    <Col className=" mt-3" sm={2}>
                      {errors.ReportStatus && (
                        <div className=" d-flex text-danger">Required</div>
                      )}
                    </Col>
                  </Row>
                )}

                <FormGroup className="d-flex ms-auto  mt-5 justify-content-between">
                  <div className="col-4 d-flex ms-auto justify-content-around">
                    <Button className="bg-primary" onClick={onClose}>
                      Close
                    </Button>
                    <Button className="bg-primary" type="submit">
                      Send
                    </Button>
                  </div>
                </FormGroup>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </div>
    </Modal>
  )
}

export default ReportStatus
