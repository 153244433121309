import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Form, FormGroup, Label, Input } from 'reactstrap';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
const UpdateUserModal = ({ isOpen, toggle }) => {
    const initialValues = {
      firstName: '',
      lastName: '',
      nationalId: '',
    };
  
    const validationSchema = Yup.object({
      firstName: Yup.string().required('First Name is required'),
      lastName: Yup.string().required('Last Name is required'),
      nationalId: Yup.string()
        .matches(/^\d+$/, 'National ID must be numeric')
        .required('National ID is required'),
    });
  
    const handleSubmit = (values) => {
      console.log(values);
      // Handle form submission
    };
  
    return (
      <Modal isOpen={isOpen} toggle={toggle}>
        <ModalHeader toggle={toggle}>Update User</ModalHeader>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ errors, touched, handleSubmit }) => (
            <Form onSubmit={handleSubmit}>
              <ModalBody>
                <FormGroup>
                  <Label for="firstName">First Name</Label>
                  <Field
                    name="firstName"
                    as={Input}
                    id="firstName"
                    placeholder="Enter first name"
                    className={touched.firstName && errors.firstName ? 'is-invalid' : ''}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="lastName">Last Name</Label>
                  <Field
                    name="lastName"
                    as={Input}
                    id="lastName"
                    placeholder="Enter last name"
                    className={touched.lastName && errors.lastName ? 'is-invalid' : ''}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="nationalId">National ID</Label>
                  <Field
                    name="nationalId"
                    as={Input}
                    id="nationalId"
                    placeholder="Enter national ID"
                    className={touched.nationalId && errors.nationalId ? 'is-invalid' : ''}
                  />
                </FormGroup>
              </ModalBody>
              <ModalFooter>
                <Button type="button" color="secondary" onClick={toggle}>
                  Cancel
                </Button>
                <Button type="submit" color="primary">
                  Update
                </Button>
              </ModalFooter>
            </Form>
          )}
        </Formik>
      </Modal>
    );
  };
  
  export default UpdateUserModal;
  