import React,{useState} from "react"
import {Table } from "reactstrap"
import AddGuideModal from "./AddGuideModal"

function ContractorGuides() {
  let contractorGuides = [
    {
      name: "contractor",
      description: "description",
      GuideEN: "GuideEN",
      GuideAR: "GuideAR",
      responsible: "responsible",
    },
  ]
  const [addGuideModal,setAddGuideModal] = useState(false)
  const toggleGuideModal = () => {
    setAddGuideModal(!addGuideModal)
  }

  return (
    <div className="page-content">
      
      {/* <Container fluid> */}
        <Table className="table-responsive project-list-table table-nowrap text-start p-2 align-middle">
          <thead>
            <th>Catagory Name</th>
            <th>Description</th>
            <th>Guide EN</th>
            <th>Guide AR</th>
            <th>Resposible</th>
            <th>Action</th>
          </thead>
          {contractorGuides?.map((item, key) => (
            <tbody key={key}>
              <td>{item.name}</td>
              <td>{item.description}</td>
              <td>
                <a
                  href={item.GuideEN}
                  target="_blank"
                  rel="noreferrer"
                  className="p-0 me-1"
                >
                  <button className="btn btn-primary btn-sm">GuideEN</button>
                </a>
              </td>
              <td>
                <a
                  href={item.GuideAR}
                  target="_blank"
                  className="p-0"
                  rel="noreferrer"
                >
                  <button className="btn btn-primary btn-sm">GuideAR</button>
                </a>
              </td>
              <td>{item.responsible}</td>
              <td>
                <span>
                <button onClick={toggleGuideModal} className="btn btn-primary btn-sm p-1">Add</button>
                </span>
              </td>
            </tbody>
          ))}
        </Table>
      {/* </Container> */}
      {addGuideModal&&<AddGuideModal toggle={toggleGuideModal} isOpen={addGuideModal} />}
    </div>
  )
}

export default ContractorGuides
