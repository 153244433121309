import React from "react"
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
} from "reactstrap"
import { Formik } from "formik"
import * as Yup from "yup"

const CustomInput = ({ field, form, ...props }) => {
  return <Input {...field} {...props} />
}

const EndUserAppSettings = () => {
  const initialValues = {
    maxOwners: "",
    maxCoordinators: "",
    maxViewers: "",
    maxVisits: "",
    maxWaitingRequests: "",
    preBookingInterval: "",
    schedulingTimeframe: "",
    maxPostponement: "",
    weekendDays: [],
  }

  const validationSchema = Yup.object({
    maxOwners: Yup.number().required("Required").min(1, "Must be at least 1"),
    maxCoordinators: Yup.number()
      .required("Required")
      .min(1, "Must be at least 1"),
    maxViewers: Yup.number().required("Required").min(1, "Must be at least 1"),
    maxVisits: Yup.number().required("Required"),
    maxWaitingRequests: Yup.number().required("Required"),
    preBookingInterval: Yup.number().required("Required"),
    schedulingTimeframe: Yup.number().required("Required"),
    maxPostponement: Yup.number().required("Required"),
    weekendDays: Yup.array().min(1, "Select at least one day"),
  })

  const handleSubmit = values => {
    console.log(values)
    // Handle submission logic here
  }

  return (
    <div className="page-content">
      <Container>
        <h3 className="mt-4 mb-4">End User App Settings</h3>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ values, errors, touched, handleChange, handleSubmit }) => (
            <Form onSubmit={handleSubmit}>
              <h5>Projects Users</h5>

              <FormGroup className="d-flex align-items-center">
                <Label className="col-3">
                  Maximum No. of Project Owners :{" "}
                </Label>
                <Col sm={1} className="mx-2 mb-2">
                  <Input
                    name="maxOwners"
                    type="number"
                    value={values.maxOwners}
                    onChange={handleChange}
                    invalid={touched.maxOwners && errors.maxOwners}
                  />
                </Col>
                <h6>user</h6>
                {touched.maxOwners && errors.maxOwners && (
                  <div className="text-danger">{errors.maxOwners}</div>
                )}
              </FormGroup>

              <FormGroup className="d-flex align-items-center">
                <Label className="col-3">Maximum No. of Coordinators : </Label>
                <Col sm={1} className="mx-2 mb-2">
                  <Input
                    name="maxCoordinators"
                    type="number"
                    value={values.maxCoordinators}
                    onChange={handleChange}
                    invalid={touched.maxCoordinators && errors.maxCoordinators}
                  />
                </Col>
                <h6>user</h6>
                {touched.maxCoordinators && errors.maxCoordinators && (
                  <div className="text-danger">{errors.maxCoordinators}</div>
                )}
              </FormGroup>

              <FormGroup className="d-flex align-items-center">
                <Label className="col-3">Maximum No. of Viewers</Label>
                <Col sm={1} className="mx-2 mb-2">
                  <Input
                    name="maxViewers"
                    type="number"
                    value={values.maxViewers}
                    onChange={handleChange}
                    invalid={touched.maxViewers && errors.maxViewers}
                  />
                </Col>
                <h6>user</h6>
                {touched.maxViewers && errors.maxViewers && (
                  <div className="text-danger">{errors.maxViewers}</div>
                )}
              </FormGroup>

              <h5>Visit Requests</h5>
              <FormGroup className="d-flex align-items-center">
                <Label className="col-3">
                  Maximum No. of Visits/Day/Inspector
                </Label>
                <Col sm={1} className="mx-2 mb-2">
                  <Input
                    name="maxVisits"
                    type="number"
                    value={values.maxVisits}
                    onChange={handleChange}
                    invalid={touched.maxVisits && errors.maxVisits}
                  />
                </Col>
                <h6>request</h6>
                {touched.maxVisits && errors.maxVisits && (
                  <div className="text-danger">{errors.maxVisits}</div>
                )}
              </FormGroup>
              <FormGroup className="d-flex align-items-center">
                <Label className="col-3">
                  Maximum No. of Waiting Requests/Day/Inspector
                </Label>
                <Col sm={1} className="mx-2 mb-2">
                  <Input
                    name="maxWaitingRequests"
                    type="number"
                    value={values.maxWaitingRequests}
                    onChange={handleChange}
                    invalid={
                      touched.maxWaitingRequests && errors.maxWaitingRequests
                    }
                  />
                </Col>
                <h6>request</h6>
                {touched.maxWaitingRequests && errors.maxWaitingRequests && (
                  <div className="text-danger">{errors.maxWaitingRequests}</div>
                )}
              </FormGroup>
              <FormGroup className="d-flex align-items-center">
                <Label className="col-3">Pre-booking Interval (Days)</Label>
                <Col sm={1} className="mx-2 mb-2">
                  <Input
                    name="preBookingInterval"
                    type="number"
                    value={values.preBookingInterval}
                    onChange={handleChange}
                    invalid={
                      touched.preBookingInterval && errors.preBookingInterval
                    }
                  />
                </Col>
                <h6>days</h6>
                {touched.preBookingInterval && errors.preBookingInterval && (
                  <div className="text-danger">{errors.preBookingInterval}</div>
                )}
              </FormGroup>
              <FormGroup className="d-flex align-items-center">
                <Label className="col-3">Scheduling Timeframe (Days)</Label>
                <Col sm={1} className="mx-2 mb-2">
                  <Input
                    name="schedulingTimeframe"
                    type="number"
                    value={values.schedulingTimeframe}
                    onChange={handleChange}
                    invalid={
                      touched.schedulingTimeframe && errors.schedulingTimeframe
                    }
                  />
                </Col>
                <h6>days</h6>
                {touched.schedulingTimeframe && errors.schedulingTimeframe && (
                  <div className="text-danger">
                    {errors.schedulingTimeframe}
                  </div>
                )}
              </FormGroup>
              <FormGroup className="d-flex align-items-center">
                <Label className="col-3">
                  Maximum Postponement Period (Days)
                </Label>
                <Col sm={1} className="mx-2 mb-2">
                  <Input
                    name="maxPostponement"
                    type="number"
                    value={values.maxPostponement}
                    onChange={handleChange}
                    invalid={touched.maxPostponement && errors.maxPostponement}
                  />
                </Col>
                <h6>days</h6>
                {touched.maxPostponement && errors.maxPostponement && (
                  <div className="text-danger">{errors.maxPostponement}</div>
                )}
              </FormGroup>
              <FormGroup className="d-flex align-items-center">
                <Label className="col-3">Weekend Days</Label>
                <div>
                  {[
                    "Saturday",
                    "Sunday",
                    "Monday",
                    "Tuesday",
                    "Wednesday",
                    "Thursday",
                    "Friday",
                  ].map(day => (
                    <Label className="me-2" key={day} check>
                      <Input
                        type="checkbox"
                        name="weekendDays"
                        className="me-1"
                        value={day}
                        onChange={handleChange}
                        // checked={values.weekendDays.includes(day)}
                      />
                      {day}
                    </Label>
                  ))}
                </div>
                {touched.weekendDays && errors.weekendDays && (
                  <div className="text-danger">{errors.weekendDays}</div>
                )}
              </FormGroup>
              <div className="d-flex justify-content-end m-2">
                <Button type="submit" color="primary">
                  Apply
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </Container>
    </div>
  )
}

export default EndUserAppSettings
