import React, { useState } from "react"
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Button,
  Table,
} from "reactstrap"
import FileViewerModal from "./FileViewer"

function ViewDriveData({ toggleViewDriveData, viewDriveData, rowData }) {
  const [fileUrl, setFileUrl] = useState("")

  const [openFileViewer, setFileViewer] = useState(false)
  const toggleFileViewer = () => setFileViewer(!openFileViewer)
  return (
    <Modal isOpen={viewDriveData} toggle={toggleViewDriveData} size="xl">
      <ModalHeader></ModalHeader>
      <ModalBody>
        <Table>
          <thead>
            <tr>
              <th>#</th>
              <th>File Name</th>
              <th>Size</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {rowData.map((item, key) => (
              <tr key={key}>
                <td>{key + 1}</td>
                <td>{item.File}</td>
                <td>{item.Size}</td>
                <td>
                  <a
                    href={item.URL}
                    download={item.URL}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Button color="secondary text-underline" size="sm">
                      <i className="fas fa-download"></i>
                    </Button>
                  </a>
                  {console.log("item.URL: ", item.URL)}

                  <Button
                    color="secondary text-underline"
                    size="sm"
                    className="ms-2"
                    onClick={() => {
                      setFileUrl(item.URL)
                      toggleFileViewer()
                    }}
                  >
                    <i className="fas fa-eye"></i>
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={toggleViewDriveData}>
          Close
        </Button>
        {/* <Button color="primary" onClick={toggleViewDriveData}>
          Download All
          </Button>  */}
      </ModalFooter>

      {openFileViewer && (
        <FileViewerModal
          fileUrl={fileUrl}
          isOpen={openFileViewer}
          toggle={toggleFileViewer}
        />
      )}
    </Modal>
  )
}

export default ViewDriveData
