import axios from "axios"
import { Formik } from "formik"
import { convertPermission } from "permissionUtils"
import React, { useState } from "react"
import { useHistory } from "react-router-dom"
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Table,
} from "reactstrap"

const ViewPQuotation = ({
  viewQuotationToggle,
  sendInstallmentQuotationToggle,
  selectedItem,
  setSelectedInstallment,
  setEditing,
  editing,
  setAuth,
}) => {
  const UserID = localStorage.getItem("id")
  // console.log("selectedItem=======>", selectedItem)

  const history = useHistory()

  const [modal, setModal] = useState(true)
  const toggle = () => {
    viewQuotationToggle()
  }

  const onClose = () => {
    viewQuotationToggle()
  }

  // **************** Permissions ******************************
  const userPermissions = convertPermission(
    JSON.parse(localStorage.getItem("roles"))
  )
  // console.log("userPermissions", userPermissions)

  // *************************************

  const dataInstallments = length => {
    return Array.from({ length }, (_, i) => ({
      id: i,
      item: selectedItem[`Instalments_${i}`],
    }))
  }
  // console.log(
  //   "dataInstallments=========>",
  //   dataInstallments(selectedItem.Instalments_No).reverse()
  // )

  const generateArray = length => {
    return Array.from({ length }, (_, index) => ({
      id: index,
      value: "",
    }))
  }

  const durationOptions = [
    { label: "today", value: "0" },
    { label: "3 months", value: "1" },
    { label: "6 months", value: "2" },
    { label: "9 months", value: "3" },
    { label: "12 months", value: "4" },
  ]

  const [paidIDs, setPaidIDs] = useState([])
  // console.log("paidIDs", paidIDs)

  const submitHandler = values => {
    // console.log("submitted values=======>", values)
    if (selectedItem.Instalments_No === 0) {
      const formData = {
        PerToken: localStorage.getItem("token"),
        PerUserID: localStorage.getItem("id"),
        PerRoleID: localStorage.getItem("userRoleID"),
      }
      formData.QuotationID = selectedItem.PQuotationID
      formData.UserID = UserID
      formData.InstallmentsNo = values.installment

      let installments = []
      for (let i = 1; i <= values.installment; i++) {
        installments.push({
          Percentage: values[`amount-${i}`],
          DueDate: values[`duration-${i}`],
        })
      }
      formData.Installments = installments

      // console.log("formData ========>", formData)

      axios
        .post(
          "https://test.rdapp.net/api/AddProjectQuotationInstallment",
          formData
        )
        .then(res => {
          if (
            res.data.error === true &&
            res.data.message === "Access denied!"
          ) {
            setAuth(true)
            setTimeout(() => {
              history.push("/logout")
              setTimeout(() => {
                history.push("/login")
              }, 1000)
            }, 4000)
          }

          // console.log("res", res)
          setEditing(!editing)
        })
        .catch(err => console.log(err))
    } else if (selectedItem.Instalments_No > 0) {
      const formData = {
        PerToken: localStorage.getItem("token"),
        PerUserID: localStorage.getItem("id"),
        PerRoleID: localStorage.getItem("userRoleID"),
        QuotationID: selectedItem.PQuotationID,
        PQIID: paidIDs,
      }

      axios
        .post(
          "https://test.rdapp.net/api/UpdateProjectQuotationPaid",
          formData
        )
        .then(res => {
          if (
            res.data.error === true &&
            res.data.message === "Access denied!"
          ) {
            setAuth(true)
            setTimeout(() => {
              history.push("/logout")
              setTimeout(() => {
                history.push("/login")
              }, 1000)
            }, 4000)
          }

          // console.log("res", res)
          setEditing(!editing)
        })
        .catch(err => console.log(err))
    }
    onClose()
  }
  return (
    <div>
      <Modal className="modal-lg" isOpen={modal} toggle={toggle}>
        <ModalHeader>
          View Quotation
          <button
            type="button"
            className="btn-close position-absolute end-0 top-0 m-3"
            onClick={onClose}
          />
        </ModalHeader>
        <ModalBody>
          <Formik
            enableReinitialize={true}
            initialValues={{
              installment: 1,
              "duration-1": "0",
            }}
            validate={values => {
              let errors = {}

              if (selectedItem.Instalments_No === 0) {
                values.totalAmount = 0
                for (let i = 1; i <= values.installment; i++) {
                  if (!values[`amount-${i}`]) errors[`amount-${i}`] = "required"
                  if (!values[`duration-${i}`])
                    errors[`duration-${i}`] = "required"

                  if (values[`duration-1`] !== "0")
                    errors[`duration-1`] = "first installment must be today"

                  if (
                    values[`duration-${i}`] * 1 >
                    values[`duration-${i + 1}`] * 1
                  )
                    errors[`duration-${i + 1}`] = "couldn't be before the above"

                  if (
                    values[`duration-${i}`] * 1 ===
                    values[`duration-${i + 1}`] * 1
                  )
                    errors[`duration-${i + 1}`] =
                      "couldn't be as same as the above"

                  values.totalAmount =
                    values.totalAmount + values[`amount-${i}`]
                }

                if (
                  !errors[`amount-1`] &&
                  !errors[`amount-2`] &&
                  !errors[`amount-3`] &&
                  values.totalAmount !== 100
                ) {
                  errors.totalAmount = "total amount must be 100%"
                } else {
                  delete errors.totalAmount
                }
              }

              console.log("values", values)
              console.log("errors", errors)
              return errors
            }}
            onSubmit={submitHandler}
          >
            {props => (
              <Form onSubmit={props.handleSubmit}>
                <Table bordered>
                  <thead>
                    <tr>
                      <th>Reference No</th>
                      <td>{selectedItem.ReferenceNo}</td>
                      <th>Payment Status</th>
                      <td>
                        {selectedItem.PaymentStatus === "Yes"
                          ? "Paid"
                          : selectedItem.PaymentStatus === "Partially"
                          ? "Partially Paid"
                          : "Not Paid"}
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th scope="row">Owner</th>
                      <td>{selectedItem.OwnerName}</td>
                      <th>Quotation Cost</th>
                      <td>{selectedItem.QuotationTotal} SAR</td>
                    </tr>
                    <tr>
                      <th scope="row">Contractor</th>
                      <td>{selectedItem.ConstructorName}</td>
                      <th>Project Cost</th>
                      <td>{selectedItem.PCost} SAR</td>
                    </tr>
                  </tbody>
                </Table>

                {selectedItem.Instalments_No > 0 && (
                  <Table bordered className="text-center">
                    <thead>
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">Amount Percentage</th>
                        <th scope="col">Due Date</th>
                        <th scope="col">Payment Status</th>
                        <th scope="col">Payment Date</th>
                        <th scope="col">Installment Quotation</th>
                      </tr>
                    </thead>
                    <tbody>
                      {dataInstallments(selectedItem.Instalments_No)
                        .reverse()
                        .map(({ item }, i) => (
                          <tr key={i}>
                            <td>{i + 1}</td>
                            <td>{item.Percentage}%</td>
                            <td>{item.DueDate}</td>
                            <td>
                              {item.Paid === "1" ? (
                                "Paid"
                              ) : (
                                <Input
                                  disabled={
                                    userPermissions.R13.P !== "3" &&
                                    userPermissions.R13.P !== "4"
                                  }
                                  type="select"
                                  onChange={e => {
                                    if (e.target.value === "1") {
                                      setPaidIDs([...paidIDs, item.PQIID])
                                    } else {
                                      setPaidIDs(
                                        paidIDs.filter(id => id !== item.PQIID)
                                      )
                                    }
                                  }}
                                >
                                  <option value={0}>Not Paid</option>
                                  <option value={1}>Paid</option>
                                </Input>
                              )}
                            </td>
                            <td>{item.PaymentDate?.split(" ")[0]}</td>
                            <td>
                              <a
                                href={`https://test.rdapp.net/uploads/reports/PQuotation/quotation.php?PQ=${selectedItem.Token}&PQID=${item.PQIID}`}
                                target="_blank"
                                rel="noreferrer"
                                className="btn btn-primary mx-1"
                              >
                                View
                              </a>
                              <Button
                                className="bg-primary mx-1"
                                onClick={() => {
                                  setSelectedInstallment(item)
                                  viewQuotationToggle()
                                  sendInstallmentQuotationToggle()
                                }}
                                disabled={
                                  userPermissions.R13.P !== "3" &&
                                  userPermissions.R13.P !== "4"
                                }
                              >
                                Send
                              </Button>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </Table>
                )}

                {selectedItem.Instalments_No === 0 && (
                  <Row>
                    <Col sm={4}>
                      <Label>Installment No.</Label>
                      <Input
                        name="installment"
                        type="select"
                        onChange={e => {
                          props.setFieldValue("installment", e.target.value)
                        }}
                      >
                        <option value={1}>1</option>
                        <option value={2}>2</option>
                        <option value={3}>3</option>
                      </Input>
                    </Col>
                    <Col sm={8}>
                      {generateArray(props.values.installment).map(
                        (item, i) => (
                          <Row key={i} className="mb-3">
                            <Col sm={6}>
                              <Label>Amount Percentage</Label>
                              <Row>
                                <Col sm={9}>
                                  <Input
                                    name={`amount-${i + 1}`}
                                    value={props.values[`amount-${i + 1}`]}
                                    onChange={props.handleChange}
                                    type="number"
                                    min={0}
                                    max={100}
                                  />
                                </Col>
                                <Col
                                  // style={{ backgroundColor: "red" }}
                                  className="d-flex align-items-center"
                                  sm={3}
                                >
                                  %
                                </Col>
                              </Row>
                              {props.errors[`amount-${i + 1}`] ? (
                                <p className="error">
                                  {props.errors[`amount-${i + 1}`]}
                                </p>
                              ) : null}
                            </Col>
                            <Col sm={6}>
                              <Label>Duration</Label>
                              <Input
                                type="select"
                                name={`duration-${i + 1}`}
                                onChange={props.handleChange}
                              >
                                <option value="" selected disabled>
                                  duration ...
                                </option>
                                {durationOptions.map(item => (
                                  <option
                                    key={item.value}
                                    value={item.value}
                                    selected={
                                      props.values[`duration-${i + 1}`] ===
                                      item.value
                                    }
                                  >
                                    {item.label}
                                  </option>
                                ))}
                              </Input>
                              {props.errors[`duration-${i + 1}`] ? (
                                <p className="error">
                                  {props.errors[`duration-${i + 1}`]}
                                </p>
                              ) : null}
                            </Col>
                          </Row>
                        )
                      )}
                      {props.errors.totalAmount ? (
                        <p className="error">{props.errors.totalAmount}</p>
                      ) : null}
                    </Col>
                  </Row>
                )}

                <FormGroup className="d-flex ms-auto  mt-5 justify-content-between">
                  <a
                    href={`https://test.rdapp.net/uploads/reports/PQuotation/intquotation.php?PQ=${selectedItem.Token}`}
                    target="_blank"
                    rel="noreferrer"
                    className="btn btn-primary mx-1 ms-4"
                  >
                    Quotation
                  </a>
                  <div className="col-3 d-flex ms-auto justify-content-around">
                    <Button
                      className="bg-primary"
                      onClick={() => {
                        onClose()
                      }}
                    >
                      Close
                    </Button>
                    <Button
                      className="bg-primary"
                      type="submit"
                      disabled={
                        selectedItem.PaymentStatus === "Yes" ||
                        (selectedItem.Instalments_No > 0 &&
                          selectedItem.PaymentStatus !== "Yes" &&
                          paidIDs.length === 0) ||
                        (userPermissions.R13.P !== "3" &&
                          userPermissions.R13.P !== "4")
                      }
                    >
                      Submit
                    </Button>
                  </div>
                </FormGroup>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </Modal>
    </div>
  )
}

export default ViewPQuotation
