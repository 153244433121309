import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Form, FormGroup, Label, Input } from 'reactstrap';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';

const PasswordChangeModal = ({ isOpen, toggle }) => {
  const initialValues = {
    password: '',
    confirmPassword: '',
    notify: false,
  };

  const validationSchema = Yup.object({
    password: Yup.string()
      .min(8, 'Password must be at least 8 characters')
      .required('Password is required'),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password')], 'Passwords must match')
      .required('Confirm Password is required'),
    notify: Yup.boolean(),
  });

  const handleSubmit = (values) => {
    console.log(values);
    // Handle form submission
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>Change Password</ModalHeader>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ errors, touched, handleSubmit }) => (
          <Form onSubmit={handleSubmit}>
            <ModalBody>
              <FormGroup>
                <Label for="password">Password</Label>
                <Field
                  name="password"
                  as={Input}
                  type="password"
                  id="password"
                  placeholder="Enter password"
                  className={touched.password && errors.password ? 'is-invalid' : ''}
                />
              </FormGroup>
              <FormGroup>
                <Label for="confirmPassword">Confirm Password</Label>
                <Field
                  name="confirmPassword"
                  as={Input}
                  type="password"
                  id="confirmPassword"
                  placeholder="Confirm password"
                  className={touched.confirmPassword && errors.confirmPassword ? 'is-invalid' : ''}
                />
              </FormGroup>
              <FormGroup check>
                <Label check>
                  <Field name="notify" type="checkbox" /> Inform Client with SMS
                </Label>
              </FormGroup>
            </ModalBody>
            <ModalFooter>
              <Button type="button" color="secondary" onClick={toggle}>
                Cancel
              </Button>
              <Button type="submit" color="primary">
                Change
              </Button>
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default PasswordChangeModal;
