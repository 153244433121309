import React, { useState, useEffect } from "react"
import { Link, useHistory, withRouter } from "react-router-dom"
import {
  Col,
  Container,
  Row,
  Table,
} from "reactstrap"
import Breadcrumbs from "components/Common/Breadcrumb"
import axios from "axios"
import PaginationComponent from "common/PaginationComponent"
import { convertPermission } from "permissionUtils"
import UnAuthorizedComponent from "common/UnAuthorizedComponent"
import VisitsFilter from "./VisitsFilter"
import LoadingInsider from "common/LoadingInsider"
import ViewModal from "./ViewModal"


const Visits = () => {
  const history = useHistory()




  // *********Document response*********/
const [reportData, setReportData] = useState([
    {
        Date: "11-2-2025",
        Stage: "saasa",
        Inspector: "asfd ds",
        Status: "qweqwew",
        ReferenceNo: " 122123"
    }
])

  // **************** Authorization ******************************
  const [auth, setAuth] = useState(false)

  // **********pagination *********************
  const [page, setPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)

  //******************************** View Toggle********************************//
  const [selectedItem, setSelectedItem] = useState()

  const [viewModal, setViewModal] = useState(false)


  // ************************** Filter section *****************************
  const initialFilteredValues = {
    reportID: [],
    referenceNo: [],
    inspector: [],
    category: [],
    startDate: "",
    endDate: "",
    inactive: "",
  }

  const [filteredValues, setFilteredValues] = useState(initialFilteredValues)



  // ***************** Asign User ***************************//
  const [asign, setAsign] = useState(false)
  const toggleAsignUser = () => {
    setAsign(!asign)
  }
  const [asignData, setAsignData] = useState({})

  // Access denied !!
  if (auth) {
    return <UnAuthorizedComponent />
  }

  const [CommunicationData, setCommunicationData] = useState({})
  const [CommunicationModal, setCommunicationModal] = useState(false)
  const toggleCommunicationData = () =>
    setCommunicationModal(!CommunicationModal)

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Reports" breadcrumbItem="Visits" />

          {/* ********************** Filter Section ***************************** */}
          <div className="d-flex col-12">
            <VisitsFilter
              filteredValues={filteredValues}
              setFilteredValues={setFilteredValues}
              setAuth={setAuth}
            />
          </div>

          <Row>
            <Col lg="12">
              <div className="">
                <div className="table-responsive">
                  {/******************* table section**************************/}
                  <Table className="project-list-table table-nowrap text-center align-middle table-borderless">
                    <thead>
                      <tr>
                        <th scope="col">Reference No.</th>
                        <th scope="col">Date</th>
                        <th scope="col">Stage</th>
                        <th scope="col">Inspector</th>
                        <th scope="col">Status</th>                  
                        <th scope="col">Action</th>
                      </tr>
                    </thead>
                    {/***********table body *****************/}
                    <tbody>
                      {reportData.map((item, key) => (
                        <tr key={key}>
                          <td>{item.ReferenceNo}</td>
                          <td>{item.Date}</td>
                          <td>{item.Stage}</td>
                          <td>{item.Inspector}</td>
                          <td>{item.Inspector}</td>

                          <td>

                              <button
                                onClick={() => {
                                  setSelectedItem(item)
                                  setViewModal(true)
                                }}
                                className="btn btn-primary btn-sm dropdown-toggle"
                              >
                                View
                              </button>

                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>

                  {reportData.length === 0 && (
                    <LoadingInsider type="spin" color="gray" />
                  )}
                </div>
              </div>
            </Col>
          </Row>

          {reportData.length > 1 && (
            <Row>
              <PaginationComponent
                page={page}
                setPage={setPage}
                totalPages={totalPages}
                setTotalPages={setTotalPages}
              />
            </Row>
          )}
          {viewModal&&<ViewModal
          toggle={()=>setViewModal(!viewModal)}
          isOpen={ViewModal}
          
          />}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Visits
