import axios from "axios"
import { Formik } from "formik"
import React, { useEffect, useState } from "react"
import { Tab, Tabs } from "react-bootstrap"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Table,
  UncontrolledTooltip,
} from "reactstrap"

const ViewRD3Report = ({
  viewToggle,
  selectedItem,
  userPermissions,
  setAuth,
  editing,
  setEditing,
}) => {
  const [declaration, setDeclaration] = useState("")
  const [modal, setModal] = useState(true)
  const toggle = () => {
    viewToggle()
  }

  const onClose = () => {
    viewToggle()
  }

  const history = useHistory()

  const [statusOptions, setStatusOptions] = useState([])

  const validateHandler = values => {
    let errors = {}

    //status error
    if (!values.status) errors.status = "required"

    //description error
    if (
      (values.status === "Rejected" || values.status === "Hold") &&
      !values.description
    )
      errors.description = "required"

    //reject reason error
    if (
      (selectedItem.DesStatus === "Approved" ||
        selectedItem.InsStatus === "Approved") &&
      (userPermissions.R24.P === "2" ||
        userPermissions.R24.P === "3" ||
        userPermissions.R24.P === "4") &&
      values.status === "Rejected" &&
      !values.rejectReason
    ) {
      errors.rejectReason = "required"
    }

    return errors
  }

  const submitHandler = values => {
    const formData = {
      PerToken: localStorage.getItem("token"),
      PerUserID: localStorage.getItem("id"),
      PerRoleID: localStorage.getItem("userRoleID"),
      RD3ID: selectedItem.RD3ID,
      
    }
    if (selectedItem.AreaManagerID === localStorage.getItem("id")) {
      formData["InsStatus"] = values.status
    } else {
      formData["Status"] = values.status
    }

    axios
      .post("https://test.rdapp.net/api/RD3/EditRD3.php", formData)
      .then(res => {
        if (res.data.error === true && res.data.message === "Access denied!") {
          setAuth(true)
          setTimeout(() => {
            history.push("/logout")
            setTimeout(() => {
              history.push("/login")
            }, 1000)
          }, 4000)
        }

        setEditing(!editing)
        onClose()
      })
      .catch(err => console.log(err))
  }

  const [RD3HistoryList, setRD3HistoryList] = useState([])
  useEffect(() => {
    const formData = {
      PerToken: localStorage.getItem("token"),
      PerUserID: localStorage.getItem("id"),
      PerRoleID: localStorage.getItem("userRoleID"),
      RD3ID: selectedItem.RD3ID,
    }

    axios
      .post("https://test.rdapp.net/api/RD3/RD3History.php", formData)
      .then(res => {
        if (res.data.error === true && res.data.message === "Access denied!") {
          setAuth(true)
          setTimeout(() => {
            history.push("/logout")
            setTimeout(() => {
              history.push("/login")
            }, 1000)
          }, 4000)
        }

        // console.log(res.data)
        let array = Object.values(res.data)
        array.splice(-1)
        setRD3HistoryList(array)
      })
      .catch(err => console.log(err))

    axios
      .post("https://test.rdapp.net/api/RD3/RD3Status.php", formData)
      .then(res => {
        if (res.data.error === true && res.data.message === "Access denied!") {
          setAuth(true)
          setTimeout(() => {
            history.push("/logout")
            setTimeout(() => {
              history.push("/login")
            }, 1000)
          }, 4000)
        }

        const options = []
        Object.keys(res.data).map(key => {
          if (Number(key)) {
            options.push({ value: key, label: res.data[key].Name })
          }
        })

        setStatusOptions(options)
      })
      .catch(err => console.log(err))
  }, [])

  useEffect(() => {
    const formData = {
      PerToken: localStorage.getItem("token"),
      PerUserID: localStorage.getItem("id"),
      PerRoleID: localStorage.getItem("userRoleID"),
      ProjectID: selectedItem.ProjectID,
    }
    axios
      .post(`https://test.rdapp.net/api/Declaration.php`, formData)
      .then(res => {
        setDeclaration(res.data.Declaration)
      })
      .catch(err => console.log(err))
  }, [])

  const submitEmailHandler = () => {
    const formData = {
      PerToken: localStorage.getItem("token"),
      PerUserID: localStorage.getItem("id"),
      PerRoleID: localStorage.getItem("userRoleID"),
      ProjectID: selectedItem.ProjectID,
    }
    axios
      .post("https://test.rdapp.net/api/RD3/EmailNotification.php", formData)
      .then(res => {
        if (res.data.error === true && res.data.message === "Access denied!") {
          setAuth(true)
          setTimeout(() => {
            history.push("/logout")
            setTimeout(() => {
              history.push("/login")
            }, 1000)
          }, 4000)
        }
        onClose()
        setEditing(!editing)
      })
      .catch(err => console.log(err))
  }

  const MangersPermission =
    (selectedItem.PStageID &&
      // Area Manger
      ((selectedItem.AreaManagerID === localStorage.getItem("id") &&
        selectedItem.StatusID == "5") ||
        selectedItem.StatusID == "5.1" ||
        selectedItem.StatusID == "5.2")) ||
    // RD3 Manger
    (userPermissions?.R31?.G === "1" &&
      (selectedItem.StatusID == "6" ||
        selectedItem.StatusID == "6.1" ||
        selectedItem.StatusID == "6.2"))

  const InpectionPermission =
        ((selectedItem.PStageID=="0" && selectedItem.StatusID == "4") &&      
            selectedItem.InsUserID === localStorage.getItem("id"))
            ||
            (selectedItem.PStageID &&
              selectedItem.InsUserID === localStorage.getItem("id") &&
              selectedItem.StatusID == "5.2"||selectedItem.StatusID == "6.2")


              

  return (
    <div>
      <Modal className="modal-lg" isOpen={modal} toggle={toggle}>
        <ModalHeader>
          View RD-3 Report
          <button
            type="button"
            className="btn-close position-absolute end-0 top-0 m-3"
            onClick={onClose}
          />
        </ModalHeader>
        <ModalBody>
          <Tabs className="mb-2" justify fill>
            <Tab eventKey="report" title="RD-3 Report">
              <Table bordered>
                <tbody>
                  <tr>
                    <th>ID</th>
                    <td>{selectedItem.RD3ID}</td>
                    <th>Owner</th>
                    <td>{selectedItem.OwnerName}</td>
                  </tr>

                  <tr>
                    <th>Reference No</th>
                    <td>{selectedItem.ReferenceNo}</td>
                    <th>Contractor</th>
                    <td>{selectedItem.ConstructorName}</td>
                  </tr>

                  <tr>
                    <th>Type</th>
                    <td>{selectedItem.Type}</td>
                    <th>Status</th>
                    <td>{selectedItem.ReportStatus}</td>
                  </tr>

                  <tr>
                    <th>Inspector</th>
                    <td>{selectedItem.InspectionName}</td>
                    <th>Reminder</th>
                    <td>
                      {" "}
                      <Button
                        className="mx-1 bg-danger"
                        onClick={submitEmailHandler}
                        // disabled={RD7Status !== selectedItem.Status}
                      >
                        Email
                      </Button>
                    </td>
                  </tr>

                  <tr>
                    <th>Design Manager</th>
                    <td>{selectedItem.DesMangerName}</td>
                    <th>Area Manager</th>
                    <td>{selectedItem.AreaManagerName}</td>
                  </tr>
                </tbody>
              </Table>


             {selectedItem.WEA?.length > 0 && <>
              <h5 className="mb-3 mt-5">
                Waterproofing Envilop Assesment Visit :{" "}
              </h5>

              <Table className="ms-5 p-2 table-responsiveproject-list-table table-nowrap text-center align-middle table-borderless">
                <thead>
                  <th>ID</th>
                  <th>respon</th>
                  <th>Result</th>
                  <th>Action</th>
                </thead>
                {selectedItem.WEA?.map((item, key) => (
                  <tbody key={key}>
                    <td>{item.StageID}</td>
                    <td>{item.InspectionName}</td>
                    <td>{item.Status}</td>
                    <td>
                      <a
                        href={item.WORD}
                        target="_blank"
                        rel="noreferrer"
                        className="p-0 me-1"
                      >
                        <button className="btn btn-primary btn-sm  ">
                          Word
                        </button>
                        {/* Word */}
                      </a>
                      <a
                        href={item.PDF}
                        target="_blank"
                        className="p-0"
                        rel="noreferrer"
                      >
                        <button className="btn btn-primary btn-sm ">PDF</button>
                        {/* Word */}
                      </a>
                    </td>
                  </tbody>
                ))}
              </Table>
              </>}

              <Formik
                initialValues={{
                  // status: initialStatus,
                  description: "",
                  // description: selectedItem.Description,
                }}
                validate={validateHandler}
                onSubmit={submitHandler}
              >
                {props => (
                  <Form className="ms-1" onSubmit={props.handleSubmit}>
                    {MangersPermission && (
                      <>
                        {/* ************************* Report Status ************************** */}
                        <Row className="mt-5">
                          <Col sm={3} className="d-flex align-items-center">
                            <Label htmlFor="report-status">
                              Change status of report {selectedItem.StageName}
                            </Label>
                          </Col>
                          <Col sm={4}>
                            <Input
                              type="select"
                              name="status"
                              id="report-status"
                              onChange={props.handleChange}
                              className="form-control border-dark"
                              disabled={!MangersPermission}
                            >
                              <option value={""}>-- choose status --</option>
                              {statusOptions.map((status, i) => (
                                <option
                                  key={i}
                                  value={status.value}
                                  selected={
                                    selectedItem.ReportStatus === status.value
                                  }
                                >
                                  {status.label}
                                </option>
                              ))}
                            </Input>
                          </Col>
                        </Row>

                        {/* ************************* Description textarea ************************** */}
                        {(props.values.status === "Rejected" ||
                          props.values.status === "Hold") && (
                          <Row className="mt-4">
                            <Col sm={3} className="d-flex align-items-center">
                              <Label htmlFor="report-status">Description</Label>
                            </Col>
                            <Col>
                              <Input
                                type="textarea"
                                name="description"
                                id="report-description"
                                value={props.values.description}
                                onChange={props.handleChange}
                                className="form-control border-dark"
                              />

                              {props.errors.description ? (
                                <p className="error mx-2 d-flex justify-content-end">
                                  {props.errors.description}
                                </p>
                              ) : null}
                            </Col>
                          </Row>
                        )}
                      </>
                    )}

                    <FormGroup className="d-flex ms-auto  mt-5 justify-content-between">
                      <div className="col-5 d-flex  justify-content-around">
                        {
                          <div>
                            <a
                              onClick={e =>
                                selectedItem.InsReport === false &&
                                e.preventDefault()
                              }
                              href={
                                selectedItem.InsReport === true
                                  ? `https://test.rdapp.net/wordlib/vendor/phpoffice/phpword/samples/rd3.php?PStageID=${selectedItem.PStageID}`
                                  : "#"
                              }
                              target="_blank"
                              rel="noopener noreferrer"
                              download={selectedItem.InsReport === true}
                            >
                              <button
                                type="button"
                                className={`btn ${selectedItem.StageID=="7"?"btn-primary":"btn-danger"} btn-label`}
                              disabled={selectedItem.InsReport === false||selectedItem.StatusID === "5.2"||selectedItem.StatusID === "6.2"}

                              >
                                Export Word
                                <i className="mdi mdi-file-word-outline ms-1 label-icon"></i>
                              </button>
                            </a>
                          </div>
                        }

                        <Button
                          className="bg-primary"
                          onClick={() => {
                            declaration
                              ? history.push(`/rd-3/${selectedItem.ProjectID}`)
                              : history.push(
                                  `/PrivacyATerms/${selectedItem.ProjectID}`,
                                  {
                                    data: selectedItem,
                                    from: "RD3",
                                    to: `/rd-3/${selectedItem.ProjectID}`,
                                  }
                                )
                          }}
                          disabled={!InpectionPermission && !MangersPermission}
                        >
                          OverView RD3
                        </Button>
                      </div>

                      <div className="col-3 d-flex  justify-content-between">
                        <Button onClick={onClose}>Close</Button>

                        {MangersPermission && (
                          <Button type="submit" className="bg-primary">
                            Submit
                          </Button>
                        )}
                      </div>
                    </FormGroup>
                  </Form>
                )}
              </Formik>
            </Tab>

            <Tab eventKey="history" title="RD3 History">
              <div style={{ maxHeight: "400px", overflowY: "scroll" }}>
                <Table className="text-center">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Updater</th>
                      <th>Action</th>
                      <th>Description</th>
                      <th>Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {RD3HistoryList.map((item, i) => (
                      <tr key={i}>
                        <th scope="row">{i + 1}</th>
                        <td>{item.UpdaterName}</td>
                        <td>{item.Action || "-"}</td>
                        <td>{item.Description || "-"}</td>
                        <td>{item.CreatedAt}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </Tab>
          </Tabs>
        </ModalBody>
      </Modal>
    </div>
  )
}

export default ViewRD3Report
