import React, { useEffect, useState } from "react"
import {
  Button,
  Form,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap"
import axios from "axios"
import Select from "react-select"

function AsignRole({data,isOpen,toggle}) {
      const [addedUser, setAddedUser] = useState([])

      const [users, setUsers] = useState([])
      useEffect(() => {
        const formData = {
          PerToken: localStorage.getItem("token"),
          PerUserID: localStorage.getItem("id"),
          PerRoleID: localStorage.getItem("userRoleID"),
          OrgChart: true,
        }
    
        axios
          .post("https://test.rdapp.net/api/Inspection_App/Visit_Users.php", formData)
          .then(res => {
            if (res.data.error === true && res.data.message === "Access denied!") {
              setAuth(true)
              setTimeout(() => {
                history.push("/logout")
                setTimeout(() => {
                  history.push("/login")
                }, 1000)
              }, 4000)
            }    
            let data = Object.values(res.data.Users)
            data.splice(-1) 
            let dataUsers = data.map(user => ({
              value: user.UserID,
              label: `${user.FirstName} ${user.LastName}`,
            }))            
            setUsers(dataUsers)
          })
          .catch(error => {
            console.log(error)
          })
      }, [])

   let errors = {}
  if (addedUser.length === 0) errors.addedUser = "required"

 

  // *********************** Submit handler ******************************** //
  const submitHandler = e => {
    e.preventDefault()

    const formData ={
    PerToken: localStorage.getItem("token"),
    PerUserID: localStorage.getItem("id"),
    PerRoleID: localStorage.getItem("userRoleID"),
    RD3ID: data.selectedItem.RD3ID,
    TransferTo: addedUser
  }

    axios
      .post("https://test.rdapp.net/api/RD3/EditRD3.php", formData)
      .then(res => {
        if (res.data.error === true && res.data.message === "Access denied!") {
          setAuth(true)
          setTimeout(() => {
            history.push("/logout")
            setTimeout(() => {
              history.push("/login")
            }, 1000)
          }, 4000)
        }

        setEditing(!editing)
      })
      .catch(err => console.log(err))

    toggle()
  }
  return (
    <Modal isOpen={isOpen} toggle={toggle} className="modal-md" style={{marginTop: "90px"}}>
      <ModalHeader>
        
        <button
          type="button"
          className="btn-close position-absolute end-0 top-0 m-1"
          onClick={toggle}
        />
      </ModalHeader>

      <ModalBody>
        <Form onSubmit={submitHandler}>
          <FormGroup>
            <Label htmlFor="employees">{data.title} :</Label>
            <Select
              
              options={users}
              name="employees"
              id="employees"
              className="react-select-container"
              onChange={e => {
                setAddedUser(e.value)
              }}
              classNamePrefix="select"
              placeholder="Choose Employees ..."
            />

            {errors.addedUser ? (
              <p className="error mx-2 d-flex justify-content-end">
                {errors.addedUser}
              </p>
            ) : null}
          </FormGroup>

          <FormGroup className="d-flex ms-auto col-4 mt-3 justify-content-between">
            <Button  onClick={toggle}>
              Cancel
            </Button>
            <Button className="bg-primary" type="submit">
              Add
            </Button>
          </FormGroup>
        </Form>
      </ModalBody>
    </Modal>
  )
}

export default AsignRole