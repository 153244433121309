import React, { useEffect, useState } from "react"
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap"

// Optional: Set the app element for accessibility

const FileViewerModal = ({ isOpen, toggle, fileUrl }) => {
  const [fileType, setFileType] = useState("pdf") // 'image' or 'pdf'

  useEffect(() => {
    fileUrl.match(/\.(jpeg|jpg|gif|png)$/i) && setFileType("image")
  }, [])

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      size="lg"
      style={{
        content: {
          maxWidth: "80%",
          maxHeight: "80%",
          margin: "auto",
          overflow: "auto",
        },
      }}
    >
      <ModalHeader>File Viewer</ModalHeader>
      <ModalBody>
      {fileType === "image" ? (
        <img
          src={fileUrl}
          alt="File Preview"
          style={{ width: "100%", height: "auto" }}
        />
      ) : fileType === "pdf" ? (
        <iframe
          src={fileUrl}
          title="PDF Viewer"
          style={{ width: "100%", height: "80vh", border: "none" }}
        />
      ) : (
        <p>Unsupported file type</p>
      )}
      </ModalBody>
      <ModalFooter>
        {" "}
        <Button onClick={toggle} style={{ float: "right" }}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default FileViewerModal
