import React, { useState, useEffect } from "react"
import { Link, useHistory, withRouter } from "react-router-dom"
import {
  Col,
  Container,
  Row,
  Table,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"
import Breadcrumbs from "components/Common/Breadcrumb"
import axios from "axios"
import PaginationComponent from "common/PaginationComponent"
import { convertPermission } from "permissionUtils"
import UnAuthorizedComponent from "common/UnAuthorizedComponent"
import LoadingInsider from "common/LoadingInsider"
import ViewLocation from "./ViewLocation"
import FilterInspection from "./FilterInspection"
import Switch from "react-switch"
import DeleteInspectionReport from "./DeleteInspectionReport"
import SendInspectionEmail from "./SendInspectionEmail"
import EditProjectData from "./EditProjectData/EditProjectData"
import { usePrevious } from "common/CustomHooks"
import ReportStatus from "./RejectReason"
import DownloadModal from "./DownloadModal"

const InspectionReports = () => {
  const history = useHistory()

  const [reports, setReports] = useState([])
  const [editing, setEditing] = useState(false)
  const [isAreaManger, setIsAreaManager] = useState(false)
  // console.log("reports", reports)

  // **************** Permissions ******************************
  const userPermissions = convertPermission(
    JSON.parse(localStorage.getItem("roles"))
  )
  // console.log("userPermissions", userPermissions)

  // **************** Authorization ******************************
  const [auth, setAuth] = useState(false)

  // **********pagination *********************
  const [page, setPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)

  //******************************** View Report Toggle********************************//
  const [selectedItem, setSelectedItem] = useState()

  const [viewModal, setViewModal] = useState(false)
  const viewToggle = () => {
    setViewModal(!viewModal)
  }

  //******************************** View Location Toggle********************************//
  const [lacationModal, setLocationModal] = useState(false)
  const locationToggle = () => {
    setLocationModal(!lacationModal)
  }

  //******************************** View Quotation Toggle********************************//
  const [viewQuotationModal, setViewQuotationModal] = useState(false)
  const viewQuotationToggle = () => {
    setViewQuotationModal(!viewQuotationModal)
  }

  //******************************** Delete Toggle********************************//
  const [deleteModal, setDeleteModal] = useState(false)
  const deleteToggle = () => {
    setDeleteModal(!deleteModal)
  }

  //******************************** Edit Toggle********************************//
  const [editModal, setEditModal] = useState(false)
  const editToggle = () => {
    setEditModal(!editModal)
  }

  //******************************** Send Email Toggle********************************//
  const [sendEmailModal, setSendEmailModal] = useState(false)
  const sendEmailToggle = () => {
    setSendEmailModal(!sendEmailModal)
  }
  //******************************** reportStatus Toggle********************************//
  const [reportStatusModal, setreportStatusModal] = useState(false)
  const reportStatusToggle = () => {
    setreportStatusModal(!reportStatusModal)
  }

  // ************************** Selected Reports *****************************
  const [selectedAllReports, setSelectedAllReports] = useState(false)
  const [selectedReports, setSelectedReports] = useState([])
  // console.log("selectedReports", selectedReports)
  const selectedReportsHandler = id => {
    if (selectedReports.includes(id)) {
      setSelectedReports(selectedReports.filter(reportID => reportID !== id))
    } else {
      setSelectedReports([...selectedReports, id])
    }
  }

  // ************************** Filter section *****************************

  const initialFilteredValues = {
    referenceNo: [],
    result: [],
    area: [],
    startDate: "",
    endDate: "",
    stage: [],
    inspector: [],
    classification: [],
    category: [],
    Momra: false,
    deletedReports: false,
    contractedReports: false,
  }
  const [filteredValues, setFilteredValues] = useState(initialFilteredValues)
  // console.log("filteredValues=========>", filteredValues)

  const prevFilteredValues = usePrevious(filteredValues)

  // ********************** Fetching reports data ****************************
  useEffect(() => {
    if (JSON.stringify(prevFilteredValues) !== JSON.stringify(filteredValues)) {
      setPage(1)
    }
    const formData = {
      PerToken: localStorage.getItem("token"),
      PerUserID: localStorage.getItem("id"),
      PerRoleID: localStorage.getItem("userRoleID"),

      Page: page,

      ReferenceNo: filteredValues.referenceNo.join(","),
      Result: filteredValues.result.map(item => item.value).join(","),
      Area: filteredValues.area.map(item => item.value).join(","),
      Stage: filteredValues.stage.map(item => item.value).join(","),
      Category: filteredValues.category.map(item => item.value).join(","),
      Classification: filteredValues.classification
        .filter(item => item.value === "1" || item.value === "0")
        .map(item => item.value)
        .join(","),
      Type: filteredValues.classification
        .filter(item => item.value === "FT" || item.value === "NT")
        .map(item => item.value)
        .join(","),

      Inspector: filteredValues.inspector.map(item => item.value).join(","),

      Deleted: filteredValues.deletedReports,
      // Contracted: filteredValues.contractedReports,

      StartDate: filteredValues.startDate,
      EndDate: filteredValues.endDate || filteredValues.startDate,
      Momra: filteredValues.Momra?2:1,
    }

    if (filteredValues.referenceNo.length > 0) {
      formData.Contracted = filteredValues.contractedReports
    }

    // console.log("formData", formData)

    axios
      .post("https://test.rdapp.net/api/inspection/InsList.php", formData)
      .then(res => {
        if (res.data.error === true && res.data.message === "Access denied!") {
          setAuth(true)
          setTimeout(() => {
            history.push("/logout")
            setTimeout(() => {
              history.push("/login")
            }, 1000)
          }, 4000)
        }

        // console.log("res", res)
        let array = Object.values(res.data)
        setTotalPages(res.data.TotalPages)
        setIsAreaManager(res.data.IsAreaManager)
        array.splice(-4)
        array.pop()
        console.log("array", array)

        setReports(array)
      })
      .catch(error => {
        console.log(error)
      })
  }, [editing, page, filteredValues])

  const [isHovering, setIsHovering] = useState(false)
  const [hoveringReport, setHoveringReport] = useState(null)

  const [downloadModal,setDownloadModal]=useState(false)
  const toggleDownload=()=>{
    setDownloadModal(!downloadModal)
  }


  // Access denied !!
  if (auth) {
    return <UnAuthorizedComponent />
  }

  // not allowed views
  if (
    userPermissions.R2.P !== "1" &&
    userPermissions.R2.P !== "2" &&
    userPermissions.R2.P !== "3" &&
    userPermissions.R2.P !== "4" &&
    userPermissions.R2.G !== "1" &&
    userPermissions.R4.P !== "1" &&
    userPermissions.R4.P !== "2" &&
    userPermissions.R4.P !== "3" &&
    userPermissions.R4.P !== "4" &&
    userPermissions.R4.G !== "1" &&
    userPermissions.R30.P !== "1" &&
    userPermissions.R30.P !== "2" &&
    userPermissions.R30.P !== "3" &&
    userPermissions.R30.P !== "4" &&
    userPermissions.R30.G !== "1"
  ) {
    return (
      <div className="page-content">
        <h3 className="d-flex justify-content-center p-3 text-light bg-danger">
          You are not authenticated!!
        </h3>
      </div>
    )
  } else {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            {/* Render Breadcrumbs */}
            <Breadcrumbs title="Reports" breadcrumbItem="Inspection Reports" />

            {/* ********************** Filter Section ***************************** */}
            <div>
              <FilterInspection
                filteredValues={filteredValues}
                setFilteredValues={setFilteredValues}
                setAuth={setAuth}
                userPermissions={userPermissions}
              />
            </div>
            {/* <div>
              <Button className=" d-flex justify-content-end btn btn-primary" onClick={toggleDownload }>Download</Button>
            </div> */}

            <Row>
              <Col lg="12">
                <div className="">
                  <div className="table-responsive">
                    {/******************* table section**************************/}
                    <Table className="project-list-table table-nowrap text-center align-middle table-borderless">
                      <thead>
                        <tr>
                          <th scope="col" style={{ width: "20px" }}>
                            <input
                              value={selectedAllReports}
                              type="checkbox"
                              onClick={e => {
                                selectedReportsHandler(e.target.value)
                                setSelectedAllReports(!selectedAllReports)
                              }}
                              className={
                                "form-check-input  p-1 border-primary "
                              }
                            />
                          </th>
                          <th scope="col">Report ID</th>
                          <th scope="col">Reference No.</th>
                          <th scope="col">Stage</th>
                          <th scope="col">R3</th>
                          <th scope="col">Result</th>
                          <th scope="col">Inspector</th>
                          <th scope="col">Date</th>
                          <th scope="col">Status</th>
                          <th scope="col">Area</th>
                          <th scope="col">Location</th>
                          <th scope="col">File</th>
                          <th scope="col"></th>
                          {(userPermissions.R2.G === "1" ||
                            userPermissions.R4.G === "1" ||
                            userPermissions.R30.G === "1" ||
                            isAreaManger) && <th scope="col">Options</th>}
                        </tr>
                      </thead>
                      {/***********table body *****************/}
                      <tbody>
                        {reports.map((item, key) => (
                          <tr key={key} style={{ position: "relative" }}>
                            <td>
                              <input
                                disabled={selectedAllReports}
                                value={item.PStageID}
                                type="checkbox"
                                onClick={e => {
                                  // console.log("---------->", e.target.value)
                                  selectedReportsHandler(e.target.value)
                                }}
                                className={
                                  "form-check-input  p-1 border-primary "
                                }
                              />
                            </td>

                            <td>{item.PStageID}</td>

                            <td>{item.ReferenceNo}</td>

                            <td>
                              <span
                                onMouseOver={() => {
                                  setIsHovering(true)
                                  setHoveringReport(item.PStageID)
                                }}
                                onMouseOut={() => {
                                  setIsHovering(false)
                                  setHoveringReport(null)
                                }}
                                style={{ cursor: "pointer" }}
                                className="my-auto"
                              >
                                {item.StageName }
                                
                              </span>
                            </td>

                            <td>
                              {item.R3 && (
                                <span className={"badge bg-warning"}>R3</span>
                              )}
                            </td>

                            {/* <td>{item.StageName}</td> */}

                            <td>{item.Status}</td>

                            <td>{item.FullName}</td>

                            <td>{item.StageDate?.split(" ")[0]}</td>
                            <td>
                              <span
                                className={
                                  item.RStatus == "Pending"
                                    ? "badge bg-warning"
                                    : item.RStatus == "Approved"
                                    ? "badge bg-success"
                                    : "badge bg-danger"
                                }
                              >
                                {item.RStatus}
                              </span>
                            </td>

                            <td>{item.Area || "-"}</td>

                            <td>
                              <span
                                className="mdi mdi-map-marker font-size-18"
                                style={{ cursor: "pointer", color: "#3B71CA" }}
                                onClick={() => {
                                  setSelectedItem(item)
                                  locationToggle()
                                }}
                              />
                            </td>

                            <td>
                              <a
                                href={`${item.WORD}`}
                                target="_blank"
                                rel="noreferrer"
                                className="btn btn-primary btn-sm mx-1"
                              >
                                Word
                              </a>
                              <a
                                href={`${item.PDF}`}
                                target="_blank"
                                rel="noreferrer"
                                className="btn btn-primary btn-sm mx-1"
                              >
                                PDF
                              </a>
                            </td>
                            <td>
                              {item.Momra && (
                                <a
                                  href={item.RStatus == "Approved"&&`${item.Momra}`}
                                  target="_blank"
                                  rel="noreferrer"
                                  className={
                                    item.RStatus == "Approved"
                                      ? `btn btn-sm mx-1  btn-primary`
                                      : "btn btn-sm mx-1  btn-secondary"
                                  }
                                  // disabled={item.RStatus != "Approved"}
                                >                                  
                                  Momra
                                </a>
                              )}
                            </td>

                            {(userPermissions.R2.G === "1" ||
                              userPermissions.R4.G === "1" ||
                              userPermissions.R30.G === "1" ||
                              isAreaManger) && (
                              <td>
                                <UncontrolledDropdown className="btn-group bg-primary">
                                  <button
                                    onClick={() => {
                                      history.push(
                                        `inspection-details/${item.PStageID}`
                                      )
                                    }}
                                    className="btn btn-primary btn-sm dropdown-toggle"
                                  >
                                    View
                                  </button>

                                  <DropdownToggle
                                    tag="a"
                                    to="#"
                                    className="card-drop"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                  >
                                    <i className="mdi mdi-dots-horizontal font-size-18 text-light me-1"></i>
                                  </DropdownToggle>
                                  <DropdownMenu className="dropdown-menu-end">
                                    <DropdownItem
                                      onClick={() => {
                                        setSelectedItem(item)
                                        sendEmailToggle()
                                      }}
                                    >
                                      Resend Email
                                    </DropdownItem>

                                    <DropdownItem
                                      onClick={() => {
                                        setSelectedItem(item)
                                        editToggle()
                                      }}
                                    >
                                      Edit Project Data
                                    </DropdownItem>

                                    <DropdownItem
                                      onClick={() => {
                                        setSelectedItem(item)
                                        deleteToggle()
                                      }}
                                    >
                                      Delete
                                    </DropdownItem>

                                    {item.RStatus == "Pending" && (
                                      <DropdownItem
                                        onClick={() => {
                                          setSelectedItem(item)
                                          reportStatusToggle()
                                        }}
                                      >
                                        Change Report Status
                                      </DropdownItem>
                                    )}
                                  </DropdownMenu>
                                </UncontrolledDropdown>
                              </td>
                            )}
                            {isHovering &&
                              hoveringReport === item.PStageID &&
                              item.StageName?.length > 17 && (
                                <p
                                  style={{
                                    position: "absolute",
                                    top: "-10%",
                                    left: "20%",
                                    zIndex: "10",
                                    backgroundColor: "#fbfcd4",
                                    border: "1px solid black",
                                    borderRadius: "5px",
                                    padding: "0 5px",
                                  }}
                                >
                                  {item.StageName}
                                </p>
                              )}
                          </tr>
                        ))}
                      </tbody>
                    </Table>

                    {reports.length === 0 && (
                      <LoadingInsider type="spin" color="gray" />
                    )}
                  </div>
                </div>
              </Col>
            </Row>

            {/* ********************* View Location *************************** */}
            {lacationModal && (
              <ViewLocation
                selectedItem={selectedItem}
                locationToggle={locationToggle}
              />
            )}

            {/* ********************* Email Report *************************** */}
            {sendEmailModal && (
              <SendInspectionEmail
                selectedItem={selectedItem}
                sendEmailToggle={sendEmailToggle}
                editing={editing}
                setEditing={setEditing}
                setAuth={setAuth}
              />
            )}
            {/* ********************* reportStatus Report *************************** */}
            {reportStatusModal && (
              <ReportStatus
                selectedItem={selectedItem}
                reportStatusModal={reportStatusModal}
                reportStatusToggle={reportStatusToggle}
                editing={editing}
                setEditing={setEditing}
                setAuth={setAuth}
              />
            )}

            {/* ********************* Delete Report *************************** */}
            {deleteModal && (
              <DeleteInspectionReport
                selectedItem={selectedItem}
                deleteToggle={deleteToggle}
                editing={editing}
                setEditing={setEditing}
                setAuth={setAuth}
              />
            )}

            {/* ********************* Delete Report *************************** */}
            {editModal && (
              <EditProjectData
                selectedItem={selectedItem}
                editToggle={editToggle}
                editing={editing}
                setEditing={setEditing}
                setAuth={setAuth}
              />
            )}

            {/* ********************* Pagination *************************** */}
            {reports.length > 0 && (
              <Row>
                <PaginationComponent
                  page={page}
                  setPage={setPage}
                  totalPages={totalPages}
                  setTotalPages={setTotalPages}
                />
              </Row>
            )}
          </Container>
          {downloadModal&&<DownloadModal isOpen={downloadModal} onClose={toggleDownload} />}
        </div>
      </React.Fragment>
    )
  }
}

export default InspectionReports
