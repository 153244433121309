import { useFormik } from "formik"
import React, { useEffect, useState } from "react"
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Table,
} from "reactstrap"
import ModifyModal from "./ModifyModal"
import ConfirmingAction from "common/ConfirmingAction"

const AddProject = ({ toggle, isOpen, editing, setEditing, setAuth }) => {
  const [isModifyModalOpen, setModifyModalOpen] = useState(false)
  const [isConfirmModalOpen, setConfirmModalOpen] = useState(false)

  let data = [
    {
      ref: "102155",
      role: "Admin",
    },
  ]

  const deleteFunc = () => {}

  const formik=useFormik(
    {
        initialValues:{
            role:""
        },
        onSubmit:values=>{
            console.log(values)
        }
    }
  )
  return (
    <Modal className="modal-lg" isOpen={isOpen} toggle={toggle}>
      <ModalHeader>
        Add Project
        <button
          type="button"
          className="btn-close position-absolute end-0 top-0 m-3"
          onClick={toggle}
        />
      </ModalHeader>
      <ModalBody>
        <div className="ms-5 mb-3">
          <Row>
            <Col>
              <Label>Owner:</Label>
            </Col>
            <Col>
              <Label>Coordinators :</Label>
            </Col>
            <Col>
              <Label>Viewer:</Label>
            </Col>
          </Row>
        </div>

        <div className=" ms-3 d-flex justify-content-between align-items-center">
          <h5>Mange Projects :</h5>
        </div>
        <Table className="ms-2 me-2 border  table-responsive project-list-table table-nowrap text-center align-middle">
          <thead>
            <th>#</th>
            <th>Ref</th>
            <th>Role</th>
            <th>Action</th>
          </thead>
          {data.map((item, key) => (
            <tbody key={key}>
              <td>{key + 1}</td>
              <td>{item.ref}</td>
              <td>{item.role}</td>
              <td>
                <span className="p-0 me-1">
                  <button
                    onClick={() => setModifyModalOpen(true)}
                    className="btn btn-primary btn-sm "
                  >
                    Modify
                  </button>
                </span>
                <span className="p-0">
                  <button
                    onClick={() => setConfirmModalOpen(true)}
                    className="btn btn-primary btn-sm"
                  >
                    Remove
                  </button>
                </span>
              </td>
            </tbody>
          ))}
        </Table>

        <Form
          onSubmit={formik.handleSubmit}>
          <div className="d-flex justify-content-around">
            <span className="h6">Choose Role </span>
            <FormGroup check>
              <Label check>
                <Input type="radio" name="role" onChange={formik.handleChange} /> Owner
              </Label>
            </FormGroup>
            <FormGroup check>
              <Label check>
                <Input type="radio" name="role" onChange={formik.handleChange}/> Coordinator
              </Label>
            </FormGroup>
            <FormGroup check>
              <Label check>
                <Input type="radio" name="role" onChange={formik.handleChange} /> Viewer
              </Label>
            </FormGroup>
          </div>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={toggle}>
          Close
        </Button>
        <Button color="primary" type="submit" onClick={formik.handleSubmit}>
          Add
        </Button>
      </ModalFooter>

      {isModifyModalOpen && (
        <ModifyModal
          isOpen={isModifyModalOpen}
          toggle={() => setModifyModalOpen(!isModifyModalOpen)}
        />
      )}
      {isConfirmModalOpen && (
        <ConfirmingAction
          ConfirmeModel={isConfirmModalOpen}
          Confirmetoggle={() => setConfirmModalOpen(!isConfirmModalOpen)}
          massege={"Are you sure you want to delete this Project?"}
          confirmFunc={deleteFunc}
        />
      )}
    </Modal>
  )
}

export default AddProject
