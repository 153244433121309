import axios from "axios"
import { Formik } from "formik"
import { convertPermission } from "permissionUtils"
import React, { useEffect, useState } from "react"
import { Tab, Tabs } from "react-bootstrap"
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Table,
  UncontrolledTooltip,
} from "reactstrap"

const ViewRD0Report = ({
  viewToggle,
  selectedItem,
  userPermissions,
  setAuth,
  editing,
  setEditing,
}) => {
  const [modal, setModal] = useState(true)
  const toggle = () => {
    viewToggle()
  }

  const onClose = () => {
    viewToggle()
  }

  const statusOptions = [
    { value: "Pending", label: "Pending" },
    { value: "Approved", label: "Approved" },
    { value: "Rejected", label: "Rejected" },
    { value: "Manual", label: "Manual" },
    { value: "InProgress", label: "InProgress" },
    { value: "Out-Of-RDAPP", label: "Out-Of-RDAPP" },
    { value: "Missing Data", label: "Missing Data" },
    { value: "Under Review", label: "Under Review" },
    { value: "Edit Required", label: "Edit Required" },
    { value: "Manager Approval", label: "Manager Approval" },
  ]

  // ************************ Related Tickets *********************************
  const [relatedTickets, setRelatedTickets] = useState([])
  // console.log("relatedTickets", relatedTickets)
  const [Tnum, setTnum] = useState(4)
  const [transferToOptions, setTransferToOptions] = useState([])

  useEffect(() => {
    const formData = {
      PerToken: localStorage.getItem("token"),
      PerUserID: localStorage.getItem("id"),
      PerRoleID: localStorage.getItem("userRoleID"),
    }
    axios
      .post(
        "https://test.rdapp.net/api/inspection/TransferToList.php",
        formData
      )
      .then(res => {
        if (res.data.error === true && res.data.message === "Access denied!") {
          setAuth(true)
          setTimeout(() => {
            history.push("/logout")
            setTimeout(() => {
              history.push("/login")
            }, 1000)
          }, 4000)
        }

        // console.log(res.data)
        let array = Object.values(res.data)
        array.splice(-1)
        setTransferToOptions([
          ...array,
          { ResourceID: "200", Name: "RD0 Issuer" },
          { ResourceID: "201", Name: "Inspection" },
        ])
      })
      .catch(err => console.log(err))

    axios
      .post("https://test.rdapp.net/api/RD0ReportStatus.php", {
        ...formData,
        PStageID: selectedItem.PStageID,
      })
      .then(res => {
        if (res.data.error === true && res.data.message === "Access denied!") {
          setAuth(true)
          setTimeout(() => {
            history.push("/logout")
            setTimeout(() => {
              history.push("/login")
            }, 1000)
          }, 4000)
        }

        if (res.data.error === false) {
          // console.log(res)
          let array = Object.values(res.data)
          array.splice(-1)
          setRelatedTickets(array)

          setTnum(array.length)
        }
      })
      .catch(err => console.log(err))
  }, [])

  const transferToHandler = value => {
    console.log("transferToHandler", value)
    if (value && transferToOptions.length > 0) {
      const user = transferToOptions.filter(item => item.ResourceID === value)
      return user[0].Name
    } else {
      return ""
    }
  }

  // ************************* Hovering Description **************************
  const [isHovering, setIsHovering] = useState(false)
  const [hoveringTicket, setHoveringTicket] = useState(null)

  const submitHandler = values => {
    let formData = {
      PerToken: localStorage.getItem("token"),
      PerUserID: localStorage.getItem("id"),
      PerRoleID: localStorage.getItem("userRoleID"),
      PStageID: selectedItem.PStageID,
      Status: values.status,
    }
        
    if (values.status === "Rejected") {
      formData = { ...formData, RejectReason: values.rejectReason }
    }
    
    axios
      .post("https://test.rdapp.net/api/UpdateReportStatus", formData)
      .then(res => {
        if (res.data.error === true && res.data.message === "Access denied!") {
          setAuth(true)
          setTimeout(() => {
            history.push("/logout")
            setTimeout(() => {
              history.push("/login")
            }, 1000)
          }, 4000)
        }

        setEditing(!editing)
      })
      .catch(err => console.log(err))

    onClose()
  }

  const validationFunction = (values) => {
    const errors = {}
    if (values.status === "Rejected" && !values.rejectReason) {
      errors.rejectReason = "Required"
    }
    return errors
  }

  return (
    <div>
      <Modal className="modal-lg" isOpen={modal} toggle={toggle}>
        <ModalHeader>
          View RD-0 Report
          <button
            type="button"
            className="btn-close position-absolute end-0 top-0 m-3"
            onClick={onClose}
          />
        </ModalHeader>
        <ModalBody>
          <Tabs className="mb-5" justify fill>
            <Tab eventKey="report" title="RD-0 Report">
              <Table bordered className="text-center mb-5">
                <thead>
                  <tr>
                    <th>Category</th>
                    <th>Stage Id</th>
                    <th>Reference No</th>
                    <th>Report Name</th>
                    <th>Rev</th>
                    <th>Created By</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <i
                        className={
                          selectedItem.Category === "Residential"
                            ? "fas fa-home"
                            : selectedItem.Category === "Hotels"
                            ? "fas fa-hotel"
                            : selectedItem.Category === "less than 23 meters"
                            ? "fas fa-building"
                            : selectedItem.Category === "Commercial Centers"
                            ? "fas fa-store"
                            : selectedItem.Category === "Entertainment Services"
                            ? "fas fa-skating"
                            : selectedItem.Category === "High Rise Towers"
                            ? "fas fa-building"
                            : selectedItem.Category === "High risk buildings"
                            ? "fas fa-building"
                            : selectedItem.Category === "Motels"
                            ? "fas fa-hotel"
                            : selectedItem.Category === "Educational"
                            ? "fas fa-school"
                            : selectedItem.Category ===
                              "Gathering buildings Mosques"
                            ? "fas fa-mosque"
                            : selectedItem.Category ===
                              "Business Buildings (Airports, Banks, TV Stations Post Offices)"
                            ? "fas fa-building"
                            : selectedItem.Category ===
                              "Gathering buildings Sports Premises"
                            ? "fas fa-running"
                            : selectedItem.Category === "Warehouses"
                            ? "fas fa-warehouse"
                            : selectedItem.Category === "Industrial"
                            ? "fas fa-industry"
                            : selectedItem.Category ===
                              "Furnished Hotel Apartments"
                            ? "fas fa-hotel"
                            : selectedItem.Category ===
                              "Gathering buildings Wedding Halls Cinemas Theaters"
                            ? "fas fa-warehouse"
                            : selectedItem.Category === "Hospitals"
                            ? "fas fa-hospital-alt"
                            : selectedItem.Category === "Healthcare Centers"
                            ? "fas fa-hospital"
                            : selectedItem.Category ===
                              "Telecommunication Towers"
                            ? "fas fa-broadcast-tower"
                            : "fas fa-building"
                        }
                        id="categorytooltip"
                      />
                      <UncontrolledTooltip
                        placement="right"
                        target="categorytooltip"
                      >
                        {selectedItem.Category || "N/A"}
                      </UncontrolledTooltip>
                    </td>
                    <td>{selectedItem.PStageID}</td>
                    <td>{selectedItem.ReferenceNo}</td>
                    <td>{selectedItem.StageName}</td>
                    <td>{selectedItem.Rev}</td>
                    <td>{selectedItem.FullName}</td>
                  </tr>
                </tbody>
              </Table>

              <Formik
                initialValues={{ status: selectedItem.Status }}
                // validate={values => console.log("values", values)}
                validate={validationFunction}
                onSubmit={submitHandler}
              >
                {props => (
                  <Form className="ms-1" onSubmit={props.handleSubmit}>
                    <Row className="mt-5">
                      <Col
                        sm={4}
                        className="d-flex align-items-center justify-content-end"
                      >
                        <Label htmlFor="report-status">
                          Change status of report {selectedItem.StageName} :
                        </Label>
                      </Col>
                      <Col sm={4}>
                        <Input
                          type="select"
                          name="status"
                          id="report-status"
                          onChange={props.handleChange}
                          className="form-control border-dark"
                          disabled={
                            Tnum * 1 > 0 ||
                            selectedItem.Status === "Approved" ||
                            selectedItem.Status === "OutOfRD0"

                            // ||
                            // userPermissions.R3.G !== "1"
                          }
                        >
                          {statusOptions.map((status, i) => (
                            <option
                              key={i}
                              value={status.value}
                              selected={selectedItem.Status === status.value}
                            >
                              {status.label}
                            </option>
                          ))}
                        </Input>
                      </Col>
                    </Row>
                    {props.values.status === "Rejected" && selectedItem.Status !== "Rejected"? (
                      <Row className="mt-3">
                        <Col
                          sm={4}
                          className="d-flex align-items-center justify-content-end "
                        >
                          <Label>Reason : </Label>
                        </Col>
                        <Col sm={6}>
                          <Input
                            type="textarea"
                            name="rejectReason"
                            onChange={props.handleChange}
                          />
                        </Col>
                        <Col sm={2}className="mt-3">
                        {props.values.status === "Rejected" &&
                      !props.values.rejectReason && (
                        <div className=" d-flex text-danger">Required</div>
                      )}
                      </Col>
                      </Row>
                    ) : (
                      " "
                    )}
                    

                    <FormGroup className="d-flex ms-auto  mt-5 justify-content-between">
                      {selectedItem.Status !== "Rejected"  &&
                        (
                          <>
                            {Tnum * 1 > 0 && userPermissions.R3.G !== "1" ? (
                              <button
                                type="button"
                                disabled={Tnum * 1 > 0 ? true : false}
                                className={
                                  selectedItem.Status !== "Approved"
                                    ? "btn-danger btn  btn-label"
                                    : "btn-primary btn  btn-label"
                                }
                              >
                                Export Word
                                <i className="mdi mdi-file-word-outline ms-1 label-icon"></i>
                              </button>
                            ) : (
                              <a
                                // href={`https://inspection.cpvarabia.com/wordlib/vendor/phpoffice/phpword/samples/rd0.php?PStageID=${selectedItem.PStageID}`}
                                href={selectedItem.Link}
                                target="_blank"
                                rel="noopener noreferrer"
                                download
                              >
                                {selectedItem.Status !== "Manual" && (
                                  <div>
                                    <button
                                      type="button"
                                      className={
                                        Tnum * 1 > 0 ||
                                        selectedItem.Status !== "Approved"
                                          ? "btn-danger btn  btn-label"
                                          : "btn-primary btn  btn-label"
                                      }
                                      id={"download"}
                                    >
                                      Export Word
                                      <i className="mdi mdi-file-word-outline ms-1 label-icon"></i>
                                    </button>
                                    {selectedItem.Status !== "Approved" && (
                                      <UncontrolledTooltip
                                        placement="right"
                                        target={"download"}
                                      >
                                        {"uncompleted or not approved"}
                                      </UncontrolledTooltip>
                                    )}
                                  </div>
                                )}
                              </a>
                            )}
                          </>
                        )}

                      <div className="col-3 d-flex ms-auto justify-content-around">
                        <Button className="bg-primary" onClick={onClose}>
                          Close
                        </Button>

                        <Button
                          type="submit"
                          className="bg-primary"
                          disabled={
                            Tnum * 1 > 0 ||
                            selectedItem.Status === "Approved" ||
                            userPermissions.R3.G !== "1"
                          }
                        >
                          Submit
                        </Button>
                      </div>
                    </FormGroup>
                  </Form>
                )}
              </Formik>
            </Tab>

            <Tab eventKey="ticket" title="Related Tickets">
              <div
                style={{
                  maxHeight: "350px",
                  overflowY: "scroll",
                  paddingBottom: "30px",
                }}
              >
                <Table bordered className="text-center mb-5">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Ticket Id</th>
                      <th>Ticket Code</th>
                      <th>Description</th>
                      <th>transfer To</th>
                    </tr>
                  </thead>
                  <tbody>
                    {relatedTickets.map((item, i) => (
                      <tr key={i} style={{ position: "relative" }}>
                        <th>{i + 1}</th>
                        <td>{item.TicketID}</td>
                        <td>{item.Code}</td>
                        <td>
                          <span
                            onMouseOver={() => {
                              setIsHovering(true)
                              setHoveringTicket(item.TicketID)
                            }}
                            onMouseOut={() => {
                              setIsHovering(false)
                              setHoveringTicket(null)
                            }}
                            style={{ cursor: "pointer" }}
                            className="my-auto"
                          >
                            {item.Description?.slice(0, 9)}
                            {item.Description?.length > 9 && " ..."}
                          </span>
                        </td>
                        <td>{transferToHandler(item.TransferTo)}</td>

                        {isHovering &&
                          hoveringTicket === item.TicketID &&
                          item.Description?.length > 9 && (
                            <p
                              style={{
                                position: "absolute",
                                top: "70%",
                                left: "15%",
                                right: "0",
                                zIndex: "10",
                                backgroundColor: "#fbfcd4",
                                border: "1px solid black",
                                borderRadius: "5px",
                                padding: "0 5px",
                              }}
                            >
                              {item.Description}
                            </p>
                          )}
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </Tab>
          </Tabs>
        </ModalBody>
      </Modal>
    </div>
  )
}

export default ViewRD0Report
