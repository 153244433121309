import React, { useState } from "react"
import {
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap"
import DatePicker from "react-datepicker"

export default function DownloadModal({ isOpen, onClose }) {
  const [dateRange, setDateRange] = useState([null, null])
  const [startDate, endDate] = dateRange

  const [dataValues, setDataValues] = useState({})

  const convertDate = value => {
    let day = value.getDate() + ""
    day = day.length === 1 ? `0${day}` : day
    let month = value.getMonth() + 1 + ""
    month = month.length === 1 ? `0${month}` : month
    let year = value.getFullYear()
    return `${year}-${month}-${day}` || null
  }

  const download = () => {
    console.log("dataValues", dataValues)

    
  }
  return (
    <Modal isOpen={isOpen} toggle={onClose} size="lg">
      <ModalHeader>Download</ModalHeader>
      <ModalBody>
        {/* ******************** Date Range *************************** */}
        <Row>
          <div className="me-3 col-12">
            <div className="position-relative mb-2 d-flex form-control border-dark ">
              <DatePicker
                isClearable={true}
                className="border-0"
                selectsRange={true}
                dateFormat="yyyy/MM/dd"
                startDate={startDate}
                endDate={endDate}
                onChange={update => {
                  setDateRange(update)
                  setDataValues({
                    ...dataValues,
                    startDate: update[0] ? convertDate(update[0]) : null,
                    endDate: update[1] ? convertDate(update[1]) : null,
                  })
                }}
                // withPortal
                placeholderText="Date ..."
              />
            </div>
          </div>
        </Row>
        <Row>
          <Col>
            <div className="form-group">
              <label htmlFor="fileType">Select File Type</label>
              <select
                className="form-control"
                id="fileType"
                onChange={e =>
                  setDataValues({ ...dataValues, fileType: e.target.value })
                }
              >
                <option value="pdf">PDF</option>
                <option value="word">Word</option>
                <option value="momra">MOMRA</option>
              </select>
            </div>
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <button
          type="button"
          className="btn btn-secondary"
          onClick={onClose}
        >
          Cancel
        </button>
        <button 
          className="btn btn-primary"
          onClick={() => {
            download()
          }}
        >
          Download
        </button>

      </ModalFooter>
    </Modal>
  )
}
